import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  Button,
  Toolbar,
  MenuItem,
  Divider,
  Box,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Chip,
  CircularProgress,
  useTheme,
} from "@mui/material/";
import Menu from '@mui/material/Menu';
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import {
  KeyboardArrowDown,
  NavigateNext,
  NavigateBefore,
  Settings,
  InfoOutlined,
  CommentRounded
} from '@mui/icons-material/';
import DebugModal from "./DebugModal";
import ShareModal from "./ShareModal";
import { useUserScopedAppData } from "../../../containers/UserScopedAppData/UserScopedAppData";
import ReviewModal from "./ReviewModal";
import { CommentType, TakerDocument } from "../../../redux/models/dataModelTypes";
import CopilotModal from "./CopilotModal";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { useReportState } from "../../../containers/TakerDocumentState/ReportState";

interface TakerDocumentStatusProps {
  takerDocument: TakerDocument;
}

const TWELVE_HOURS_MS = (1000 * 60 * 60 * 12);

const TakerDocumentStatus = ({
  takerDocument
}: TakerDocumentStatusProps) => {
  if (takerDocument.state && takerDocument.state.reviewState === "COMPLETED") {
    return (
      <Chip
        size="small"
        variant="outlined"
        color="success"
        label="Completed"
      />
    );
  } else if (takerDocument.state && takerDocument.state.reviewState === "DRAFT") {
    return (
      <Chip
        size="small"
        variant="outlined"
        color="info"
        label="Draft"
      />
    );
  } else if (takerDocument.state && takerDocument.state.reviewState === "PENDING") {
    return (
      <Chip
        size="small"
        variant="outlined"
        color="default"
        label="In Review"
      />
    );
  }
  return (
    <Chip
      size="small"
      variant="outlined"
      color="warning"
      label="Unknown"
    />
  );
}

interface TakerToolbarProps {
  availableWidgets?: {
    id: string;
    display: string;
  }[];
  onClickBack?: () => void;
  onClickForward?: () => void;
  expectedCommentType?: CommentType;
  workflowDisplay: string;
  workflowDescription: string;
  activeWidgets?: Array<string>;
  addActiveWidget?: (id: string) => void;
  removeActiveWidget?: (id: string) => void;
}

interface AutoScalingInputProps {
  defaultName: string;
  onChange: (s: string) => void;
}

// TODO: combine with the indexed guidance UI one
const AutoScalingInput = ({
  defaultName,
  onChange
}: AutoScalingInputProps) => {
  const [content, setContent] = useState(defaultName || '');
  const [width, setWidth] = useState<number>(0);
  const spanElem = useRef<HTMLSpanElement>(null);
  const theme = useTheme();

  useEffect(() => {
    if (spanElem.current) {
      setWidth(spanElem.current.offsetWidth * 1.1);
    }
  }, [content]);

  const changeHandler = (evt: any) => {
    setContent(evt.target.value);
    onChange(evt.target.value);
  };

  return (
    <Box>
      <span
        ref={spanElem}
        style={{
          position: "absolute",
          opacity: 0,
          zIndex: -100,
          whiteSpace: "pre",
          fontSize: "1.4em"
        }}>
        {content}
      </span>
      <input
        type="text"
        style={{
          fontSize: "1.4em",
          width: width,
          border: "none",
          backgroundColor: "transparent"
        }}
        autoFocus
        onChange={changeHandler}
        value={defaultName}
      />
    </Box>
  );
};

const TakerToolbar = ({
  onClickBack,
  onClickForward,
  expectedCommentType,
  workflowDisplay,
  workflowDescription
}: TakerToolbarProps) => {
  const {
    takerName,
    updateTakerName,
    activeTakerDocument,
    takerPermissionState,
    isTakerStateDirty,
    lastSavedTimestamp: questionnaireLastSaved,
    commentsOpen,
    setCommentsOpen,
    reviewComments
  } = useTakerState();
  const {
    isKeyTermGroupStateDirty,
    lastSavedTimestamp: keyTermGroupLastSaved
  } = useKeyTermGroupState();
  const {
    isReportStateDirty,
    lastSavedTimestamp: reportLastSaved
  } = useReportState();
  const {
    flagProvider,
    isDebugger
  } = useUserScopedAppData();

  const [viewDiagnostics, setViewDiagnostics] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openCopilotModal, setOpenCopilotModal] = useState(false);

  const [assitantAnchorEl, setAssistantAnchorEl] = useState<null | HTMLElement>(null);
  const assistantOpen = Boolean(assitantAnchorEl);

  const enableTakerShareFlow = useMemo(
    () => flagProvider.populateFlagValues([
      "FEATURE_SHARE_TAKERS__enable_taker_share_flow"
    ]).FEATURE_SHARE_TAKERS__enable_taker_share_flow === "TRUE",
    [flagProvider]
  );

  const isAdmin = useMemo(() =>
    enableTakerShareFlow && takerPermissionState.isAdmin,
    [enableTakerShareFlow, takerPermissionState]
  );

  const settings = useMemo(() => {
    let menuItems = [];
    if (isDebugger) {
      menuItems.push(
        <MenuItem
          color="inherit"
          disableRipple
          onClick={(e: any) => {
            setViewDiagnostics(true);
          }}
        >
          Debug
        </MenuItem>
      );
    }

    menuItems.push(
      <MenuItem
        color="inherit"
        disableRipple
        onClick={(e: any) => {
          setOpenShareModal(true);
        }}
      >
        Share
      </MenuItem>
    );
    menuItems.push(
      <MenuItem
        color="inherit"
        disableRipple
        onClick={(e: any) => {
          setOpenReviewModal(true);
        }}
      >
        Review
      </MenuItem>
    );

    if (isDebugger) {
      menuItems.push(
        <MenuItem
          color="inherit"
          disableRipple
          onClick={(e: any) => {
            setOpenCopilotModal(true);
          }}
        >
          Copilot
        </MenuItem>
      );
    }

    if (menuItems.length > 0) {
      return (
        <>
          <Button
            data-testid="taker-toolbar-settings-button"
            aria-haspopup="true"
            aria-expanded={assistantOpen ? 'true' : undefined}
            disableElevation
            startIcon={<Settings />}
            onClick={(event: React.MouseEvent<HTMLElement>) => setAssistantAnchorEl(event.currentTarget)}
            endIcon={<KeyboardArrowDown />}
          >
          </Button>
          <Menu
            elevation={1}
            anchorEl={assitantAnchorEl}
            open={assistantOpen}
            onClose={() => setAssistantAnchorEl(null)}
          >
            {menuItems}
          </Menu>
        </>
      );
    }
    return null;
  }, [assitantAnchorEl, assistantOpen, isDebugger, isAdmin]);

  const lastSavedDateStr = useMemo(() => {
    let lastSavedTimestamp = undefined;
    
    // First check questionnaire saved time.
    if (!lastSavedTimestamp || questionnaireLastSaved) {
      lastSavedTimestamp = questionnaireLastSaved;
    }

    // Check key term group last saved time.
    if (
      !lastSavedTimestamp || 
      (keyTermGroupLastSaved && keyTermGroupLastSaved > lastSavedTimestamp)
    ) {
      lastSavedTimestamp = keyTermGroupLastSaved;
    }

    // Check report saved time.
    if (
      !lastSavedTimestamp || 
      (reportLastSaved && reportLastSaved > lastSavedTimestamp)
    ) {
      lastSavedTimestamp = reportLastSaved;
    }
  
    if (!lastSavedTimestamp) {
      return;
    } 
    
    const nowTs = new Date().getTime();
    const lastSavedDate = new Date(lastSavedTimestamp);
    if (nowTs - lastSavedTimestamp > TWELVE_HOURS_MS) {
      return `last saved on ${lastSavedDate.toLocaleString()}`;
    }
    return `last saved at ${lastSavedDate.toLocaleTimeString()}`;
  }, [
    questionnaireLastSaved, 
    keyTermGroupLastSaved, 
    reportLastSaved
  ]);

  const hasComments = useMemo(() => {
    if (!expectedCommentType || reviewComments.length === 0) {
      return false;
    }
    return reviewComments.some((comment) => comment.commentType === expectedCommentType);
  }, [reviewComments, expectedCommentType]);

  return (
    <>
      <Toolbar 
        disableGutters
        variant="dense"
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack
            direction="row"
            sx={{
              alignItems: "center"
            }}
          >
            <AutoScalingInput
              defaultName={takerName}
              onChange={(tn) => {
                updateTakerName(tn);
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "flex-start"
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& hr': {
                    mx: 0.5,
                  },
                }}
              >
                {activeTakerDocument && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <TakerDocumentStatus takerDocument={activeTakerDocument} />
                  </>
                )}
                {settings && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    {settings}
                  </>
                )}
                  <>
                    <Divider orientation="vertical" flexItem />
                    <IconButton 
                      data-testid="taker-toolbar-comments-button"
                      size="small"
                      color={hasComments ? "primary" : "default"}
                      onClick={() => setCommentsOpen(!commentsOpen)}
                    >
                      <CommentRounded />
                    </IconButton>
                  </>
                {lastSavedDateStr && (
                  <>
                    <Divider orientation="vertical" flexItem />
                      {(isTakerStateDirty || isKeyTermGroupStateDirty || isReportStateDirty) ? (
                        <span style={{ marginLeft: "5px"}}>
                          <CircularProgress size={16}/>
                        </span>
                      ) : (
                        <Typography
                          component="span"
                          variant="caption" 
                          color="text.secondary"
                          sx={{ marginLeft: "5px"}}
                        >
                          {lastSavedDateStr}
                        </Typography>
                      )}
                  </>
                )}
              </Box>
            </div>
          </Stack>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Stack>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center'
              }}
            >
              <IconButton
                data-testid="taker-toolbar-back-button"
                size="large"
                disabled={!onClickBack}
                onClick={onClickBack}
                color="primary"
              >
                <NavigateBefore />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  width: '100%',
                }}
                noWrap
              >
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  {workflowDisplay}&nbsp;
                  <Tooltip title={workflowDescription} placement="left-end">
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                </div>
              </Typography>
              <IconButton
                data-testid="taker-toolbar-forward-button"
                size="large"
                disabled={!onClickForward}
                onClick={onClickForward}
                color="primary"
              >
                <NavigateNext />
              </IconButton>
            </Box>
          </Stack>
        </Box>
      </Toolbar>
      {viewDiagnostics && (
        <DebugModal
          open={viewDiagnostics}
          setOpen={setViewDiagnostics}
        />
      )}
      <ShareModal
        open={openShareModal}
        setOpen={setOpenShareModal}
      />
      <ReviewModal
        open={openReviewModal}
        setOpen={setOpenReviewModal}
      />
      {activeTakerDocument?.settings && (
        <CopilotModal
          open={openCopilotModal}
          setOpen={setOpenCopilotModal}
        />
      )}
    </>
  );
}

export default TakerToolbar;