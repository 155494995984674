import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { LexicalEditor } from 'lexical';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import AddTermFloatingToolbar from './AddTermFloatingToolbar';
import EditTermFloatingToolbar from './EditTermFloatingToolbar';
import { DocumentAnnotation } from '../../../../types/taker/documentkeyterms.generated';

import './index.css';

function useFloatingTextToolbar(
  editor: LexicalEditor,
  anchorElem: HTMLElement,
  annotations: DocumentAnnotation[],
  isV2Render: boolean,
  lexicalDocumentIdentifier: string,
  pageIndex: number
): JSX.Element | null {
  const [isHighlightingText, setIsHighlightingText] = useState(false);
  const [selectedElementId, setSelectedElementId] = useState<string>();

  const annotatedElementIdSet = useMemo(
    () => new Set(annotations.flatMap(da => da.documentHighlights.map(dh => dh.elementId))),
    [annotations]
  );

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the floating toolbar when using IME input
      if (editor.isComposing()) {
        return;
      }
      
      const nativeSelection = window.getSelection();
      const rootElement = editor.getRootElement();

      if (
        (nativeSelection !== null && (rootElement === null || !rootElement.contains(nativeSelection.anchorNode))) 
        || nativeSelection === null
      ) {
        setSelectedElementId(undefined);
        setIsHighlightingText(false);
        return;
      }

      let isRangeSelection = nativeSelection.type === "Range";
      let isCaretSelection = nativeSelection.type === "Caret";
      if (isCaretSelection) {
        const anchorNode = nativeSelection.anchorNode;
        if (anchorNode !== null) {
          const parentElement = anchorNode.parentElement;
          if (!!parentElement) {
            // check words, lines and textboxes
            const idParts: string[] = parentElement.id.split('-');
            let selectedSubIdInSet = undefined;
            for (let i = 0; i < 3; i++) {
              let subId = idParts.slice(0, (idParts.length - i)).join('-');
              if (annotatedElementIdSet.has(subId)) {
                selectedSubIdInSet = subId;
                break;
              }
            }
            setSelectedElementId(selectedSubIdInSet);
          } else {
            setSelectedElementId(undefined);
          }
        } else {
          setSelectedElementId(undefined);
        }
        setIsHighlightingText(false);
      } else if (isRangeSelection) {
        const rawTextContent = nativeSelection.toString().replace(/\n/g, '');
        if (!nativeSelection.isCollapsed && rawTextContent === '') {
          setIsHighlightingText(false);
        } else {
          setIsHighlightingText(true);
        }
        setSelectedElementId(undefined);
      } else {
        setSelectedElementId(undefined);
        setIsHighlightingText(false);
      }
    });
  }, [editor, annotatedElementIdSet]);

  useEffect(() => {
    document.addEventListener('selectionchange', updatePopup);
    return () => {
      document.removeEventListener('selectionchange', updatePopup);
    };
  }, [updatePopup]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup();
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsHighlightingText(false);
          setSelectedElementId(undefined);
        }
      }),
    );
  }, [editor, updatePopup]);

  if (isHighlightingText) {
    return createPortal(
      <AddTermFloatingToolbar
        editor={editor}
        anchorElem={anchorElem}
        isV2Render={isV2Render}
        lexicalDocumentIdentifier={lexicalDocumentIdentifier}
        pageIndex={pageIndex}
      />,
      anchorElem,
    );
  } else if (selectedElementId !== undefined) {
    return createPortal(
      <EditTermFloatingToolbar
        editor={editor}
        anchorElem={anchorElem}
        selectedElementId={selectedElementId}
      />,
      anchorElem,
    );
  }
  return null;
}

export default function FloatingAnnotationToolbarPlugin({
  anchorElem = document.body,
  annotations,
  isV2Render,
  lexicalDocumentIdentifier,
  pageIndex
}: {
  anchorElem?: HTMLElement;
  annotations: DocumentAnnotation[];
  isV2Render: boolean;
  lexicalDocumentIdentifier: string;
  pageIndex: number;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  return useFloatingTextToolbar(
    editor,
    anchorElem,
    annotations,
    isV2Render,
    lexicalDocumentIdentifier,
    pageIndex
  );
}