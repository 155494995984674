import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    ListItem,
    IconButton,
    Chip,
    Divider,
    TextField,
    Tooltip
} from "@mui/material/";
import { ArrowUpward, ArrowDownward, Edit, Delete, ContentPasteGoRounded, Save, Cancel } from "@mui/icons-material";
import { KeyTerm, DocumentHighlight } from "../../../types/taker/documentkeyterms.generated";
import { KeyTermsState } from "../../../containers/WidgetWrapper/states";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import EditableSummaryV2 from "../../../components/form/EditableSummaryV2";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import KeyTermDetailModal from "./KeyTermDetailModal";
import ConfirmationDialog from "../../../components/dialog/GenericConfirmation";
import { flushSync } from "react-dom";

interface KeyTermListItemProps {
    takerDocumentUploadId?: string;
    saveAll: boolean;
    keyTerm: KeyTerm;
    myIndex: number;
    totalLength?: number;
    readOnly: boolean;
}

const KeyTermListItem = ({
    takerDocumentUploadId,
    saveAll,
    keyTerm,
    myIndex,
    totalLength,
    readOnly,
}: KeyTermListItemProps) => {
    const { documentKeyTermsService, currentlyOpenDetailsModal } = useKeyTermGroupState();
    const { getState, mutateState } = useWidgetState();
    const [editOpen, setEditOpen] = useState<number>();
    const [needConfirm, setNeedConfirm] = useState<boolean>(false);
    const [localTermName, setLocalTermName] = useState<string>(keyTerm.termName);
    const [localSummary, setLocalSummary] = useState<string>(keyTerm.summary);
    const [editing, setEditing] = useState<boolean>(false);
    const [importedContent, setImportedContent] = useState<string[]>();
    const [localSaveAll, setLocalSaveAll] = useState<boolean>(saveAll);

    const deleteClicked = () => {
        setNeedConfirm(true);
    }

    const fileUploadItemId = getState<KeyTermsState>().targetFileUploadItemId;
    const showExpandedSummaries = getState<KeyTermsState>().showExpandedSummaries;

    interface PageAnnotationData {
        firstElementID: string | undefined,
        documentHighlights: DocumentHighlight[],
        annotationIds: string[]
    }
    const combinedRefs: Record<number, PageAnnotationData> = {};
    if (keyTerm.documentAnnotations) {
        for (const da of keyTerm.documentAnnotations) {
            if (da.lexicalDocumentIdentifier === fileUploadItemId) {
                if (!combinedRefs[da.page]) {
                    combinedRefs[da.page] = {
                        firstElementID: da.documentHighlights[0]?.elementId,
                        documentHighlights: da.documentHighlights,
                        annotationIds: [da.annotationId]
                    };
                } else {
                    combinedRefs[da.page].documentHighlights.push(...da.documentHighlights);
                    combinedRefs[da.page].annotationIds.push(da.annotationId);
                }
            }
        }
    }

    const entries = Object.entries(combinedRefs);

    useEffect(() => {
        setLocalSummary(keyTerm.summary);
    }, [keyTerm.summary]);

    useEffect(() => {
        setLocalTermName(keyTerm.termName);
    }, [keyTerm.termName]);

    useEffect(() => {
        if (saveAll !== localSaveAll) {
            documentKeyTermsService.flushKeyTermInfoAndSummary(keyTerm.termName, localTermName, keyTerm.categories, localSummary);
            setEditing(false);
        }
        setLocalSaveAll(saveAll);
    }, [saveAll])

    let isEditing = editing;

    return (
        <ListItem
            data-testid={`key-term-list-item-${myIndex}`}
            key={`${takerDocumentUploadId}_${keyTerm.identifier}`}
            sx={{
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0
            }}
        >
            <Grid
                container
                padding={1}
                sx={{
                    backgroundColor: "white",
                    borderRadius: 1,
                    border: "1px solid rgb(195, 195, 195)",
                }}
            >
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                >
                    <Grid
                        item
                        container
                        justifyContent="start"
                        alignItems="center"
                        xs={6}
                        marginBottom={1}
                    >
                        <TextField
                            data-testid={`key-term-name-${keyTerm.termName}`}
                            id={`key-term-name-${keyTerm.termName}`}
                            size="small"
                            value={localTermName}
                            fullWidth
                            onChange={(e: any) => {
                                if (!readOnly) {
                                    setLocalTermName(e.target.value);
                                }
                            }}
                            onClick={() => {
                                if (!readOnly && !isEditing) {
                                    setEditing(true);
                                }
                            }}
                            sx={{
                                fieldset: {
                                    border: "1px solid white",
                                }
                            }}
                            InputProps={{
                                readOnly: readOnly || !isEditing,
                                autoComplete: "off"
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="end"
                        alignItems="center"
                        xs={6}
                    >
                        {isEditing && (
                            <>
                                <Tooltip title={"Save"}>
                                    <IconButton
                                        data-testid={`key-term-save-${keyTerm.termName}`}
                                        disabled={readOnly}
                                        size="small"
                                        onClick={(e) => {
                                            documentKeyTermsService.updateKeyTermInfoAndSummary(keyTerm.termName, localTermName, keyTerm.categories, localSummary);
                                            setEditing(false);
                                        }}
                                        color="info"
                                    >
                                        <Save />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Cancel"}>
                                    <IconButton
                                        data-testid={`key-term-cancel-${keyTerm.termName}`}
                                        disabled={readOnly}
                                        size="small"
                                        color="error"
                                        onClick={(e) => {
                                            setEditing(!isEditing);
                                            setLocalSummary(keyTerm.summary);
                                        }}
                                    >
                                        <Cancel />
                                    </IconButton>
                                </Tooltip>
                                <Divider orientation="vertical" flexItem />
                            </>
                        )}
                        <Grid item>
                            <Tooltip title={"Delete Key Term"}>
                                <IconButton
                                    data-testid={`key-term-delete-${keyTerm.termName}`}
                                    disabled={readOnly}
                                    size="small"
                                    onClick={() =>
                                        deleteClicked()
                                    }
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={"Import Highlight Text"}>
                                <IconButton
                                    data-testid={`key-term-import-to-summary-${keyTerm.termName}`}
                                    disabled={readOnly}
                                    size="small"
                                    onClick={() => {
                                        setImportedContent(keyTerm?.documentAnnotations?.map((annotationObject) =>
                                            documentKeyTermsService.getTextForAnnotation(annotationObject.annotationId)
                                        ));
                                        setEditing(true);
                                    }}
                                >
                                    <ContentPasteGoRounded />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {(!readOnly) && (
                            <Grid item>
                                <Tooltip title={"Edit Details"}>
                                    <IconButton
                                        data-testid={`key-term-edit-${keyTerm.termName}`}
                                        disabled={readOnly}
                                        size="small"
                                        onClick={() => {
                                            documentKeyTermsService.setOpenDetailsModal(myIndex)
                                            setEditOpen(myIndex)
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                        {!readOnly && (
                            <Grid item>
                                <Tooltip title={"Move Up"}>
                                    <IconButton
                                        data-testid={`key-term-up-${keyTerm.termName}`}
                                        disabled={myIndex === 0 || readOnly}
                                        size="small"
                                        onClick={() =>
                                            documentKeyTermsService.shiftKeyTermUp(keyTerm.termName)
                                        }
                                    >
                                        <ArrowUpward />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                        {(!readOnly && totalLength !== undefined) && (
                            <Grid item>
                                <Tooltip title={"Move Down"}>
                                    <IconButton
                                        data-testid={`key-term-down-${keyTerm.termName}`}
                                        disabled={myIndex === totalLength - 1 || readOnly}
                                        size="small"
                                        onClick={() =>
                                            documentKeyTermsService.shiftKeyTermDown(keyTerm.termName)
                                        }
                                    >
                                        <ArrowDownward />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}
                    onClick={() => {
                        if (!readOnly) {
                            setEditing(true);
                        }
                    }}
                >
                    <Divider />
                    <EditableSummaryV2
                        keyTermName={keyTerm.termName}
                        triggerEdit={isEditing}
                        defaultValue={localSummary}
                        importedContent={importedContent}
                        expandSummary={showExpandedSummaries}
                        onChangeValue={(s: string) => {
                            if (!readOnly) {
                                setLocalSummary(s);
                            }
                        }}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box paddingTop={1}>
                        {entries.length > 0 ? (
                            entries.map(([page, pageAnnotataionData]) => {
                                let numPage = parseInt(page);
                                return (
                                    <Chip
                                        sx={{ marginLeft: "5px", marginRight: "5px" }}
                                        size="small"
                                        label={`page ${numPage + 1}`}
                                        onClick={() => mutateState<KeyTermsState>({
                                            scrollToPage: numPage,
                                            scrollToElementID: pageAnnotataionData.firstElementID,
                                            navigateHighlightElementIDs: pageAnnotataionData.documentHighlights,
                                            activeAnnotationIds: pageAnnotataionData.annotationIds,
                                        })}
                                    />
                                );
                            })
                        ) : (
                            <span>
                                No References
                            </span>
                        )}
                    </Box>
                </Grid>
            </Grid>
            {needConfirm && (
                <ConfirmationDialog
                    title="Confirmation"
                    confirmationText={`Are you sure you want to delete key term "${keyTerm.termName}"`}
                    onConfirmed={() => {
                        documentKeyTermsService.removeKeyTerm(keyTerm.termName)
                        setNeedConfirm(false);
                    }}
                    onCancelled={() => {
                        setNeedConfirm(false);
                    }}
                />
            )}
            <KeyTermDetailModal
                readOnly={readOnly}
                defaultKeyTerm={keyTerm}
                open={currentlyOpenDetailsModal === myIndex}
                onClose={() => documentKeyTermsService.setOpenDetailsModal(undefined)}
            />
        </ListItem>
    );
};

export default KeyTermListItem;