import React, { useState, useCallback, memo } from 'react';
import { Box, Button, Divider, Grid, Toolbar, Typography } from '@mui/material';
import { TakerDocumentReviewComment, TakerDocumentReviewCommentPayload, User } from '../../../redux/models/dataModelTypes';
import { useLocalStorage } from '@uidotdev/usehooks';
import { CreateNewCommentPlain } from './CreateNewComment';
import CommentOptionsMenu from '../../comments/CommentOptionsMenu';
import { CommentDateDisplay, CommentUserDisplay } from '../../comments/CommentMetaDisplay';
import HighlightCommentMeta from './HighlightCommentMeta';
import CommentLexical from '../../comments/CommentLexical';
import { blue } from '@mui/material/colors';

interface CommentThreadProps {
    index: number;
    takerDocumentId: string;
    commentsOpen: boolean;
    authedUser: User;
    threadId: string;
    comments: TakerDocumentReviewComment[];
    enableReplies: boolean;
    onPostComment: (pl: TakerDocumentReviewCommentPayload) => void;
    onUpdateComment: (id: string, pl: TakerDocumentReviewCommentPayload) => void;
    isSelected: boolean;
    setIsSelected: (isSelected: boolean) => void;
}

/**
 * CommentThread is a component that renders a single thread of comments.
 * Each comment is rendered with its text, author, and timestamp.
 * If the user is authenticated, they will also see a reply button.
 * If the user replies to a comment, the reply will be displayed below the original comment.
 * If the user is authenticated and has permission to post, they will also see a "Post" button.
 * If the user posts a comment, it will be added to the thread.
 * The component also handles saving the user's staged comment to local storage.
 * @param {{ takerDocumentId: string; authedUser: User; threadId: string; comments: TakerDocumentReviewComment[]; enableReplies: boolean; onPostComment: (pl: TakerDocumentReviewCommentPayload) => void; isSelected: boolean; setIsSelected: (isSelected: boolean) => void; }} props
 * @prop {string} takerDocumentId The ID of the document this comment thread is for.
 * @prop {User} authedUser The user who is currently authenticated.
 * @prop {string} threadId The ID of the thread this comment is part of.
 * @prop {TakerDocumentReviewComment[]} comments The comments in this thread.
 * @prop {boolean} enableReplies If true, the user will be able to reply to comments.
 * @prop {(pl: TakerDocumentReviewCommentPayload) => void} onPostComment A callback that will be called when the user posts a comment.
 * @prop {boolean} isSelected If true, the comment thread is selected.
 * @prop {(isSelected: boolean) => void} setIsSelected A callback that will be called when the user selects or deselects the comment thread.
 * @returns {JSX.Element} The CommentThread component.
 */
const CommentThread = ({
    index,
    commentsOpen,
    takerDocumentId,
    authedUser,
    threadId,
    comments,
    enableReplies,
    onPostComment,
    onUpdateComment,
    isSelected,
    setIsSelected
}: CommentThreadProps) => {
    const [stagedComment, setStagedComment] = useLocalStorage<TakerDocumentReviewCommentPayload | undefined>(`${takerDocumentId}-${threadId}-stagedReplyComment`);
    const [showReplyComment, setShowReplyComment] = useState(false);
    const [editComment, setEditComment] = useState<string>();

    const border = isSelected ? `2px solid ${blue[400]}` : "1px solid #ddd";

    const stageCommentFromReply = useCallback((commentType: "DOCUMENT_HIGHLIGHT" | "TOP_LEVEL") => {
        if (stagedComment === undefined) {
            setStagedComment({
                commentType,
                commentLexical: undefined,
                commentText: "",
                commentMetadata: {
                    threadId
                }
            });
        }
        setShowReplyComment(true);
        setEditComment(undefined);
    }, [threadId]);

    const pendingReply = !!stagedComment && showReplyComment && enableReplies;

    if (comments.length === 0) {
        // No comments, nothing to display
        return null;
    } else if (comments.length === 1) {
        // This is just a plain comment, with optional replies 
        const onlyComment = comments[0];
        return (
            <Box
                sx={{
                    marginTop: 1,
                    padding: 1,
                    border,
                    backgroundColor: "white",
                    borderRadius: 1
                }}
                data-testid={`comment-thread-${index}`}
            >
                <HighlightCommentMeta 
                    key={`commentMeta-${onlyComment.id}`}
                    commentMetadata={onlyComment.commentMetadata} 
                />
                <Divider 
                    sx={{ 
                        marginBottom: 0.5,
                        marginTop: 1
                    }} 
                />
                <Box key={`commentHeader-${onlyComment.id}`}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                            noWrap
                        >
                            <CommentUserDisplay comment={onlyComment} />
                        </Typography>
                        <div>
                            <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                sx={{ marginRight: 1 }}
                                noWrap
                            >
                                <CommentDateDisplay comment={onlyComment} />
                            </Typography>
                            <CommentOptionsMenu
                                viewerUser={authedUser}
                                comment={onlyComment}
                                takerDocumentId={takerDocumentId}
                                extraMenuItems={(authedUser.id === onlyComment.userId) ? [
                                    {
                                        onClick: () => {
                                            setStagedComment(onlyComment);
                                            setEditComment(onlyComment.id);
                                            setShowReplyComment(false);
                                        },
                                        label: "Edit"
                                    }
                                ]: undefined}
                            />
                        </div>
                    </Box>
                    {(editComment === onlyComment.id && !!stagedComment) ? (
                        <CreateNewCommentPlain
                            key={`commentEdit-${onlyComment.id}`}
                            sx={{
                                padding: 1,
                                border: "1px solid rgba(221, 221, 221, 0.5)",
                                backgroundColor: "rgba(221, 221, 221, 0.05)",
                                borderRadius: 1
                            }}
                            stagedComment={stagedComment}
                            updateStagedComment={setStagedComment}
                            onClearStagedComment={() => {
                                setStagedComment(undefined);
                                setEditComment(undefined);
                            }}
                            onPostComment={() => {
                                onUpdateComment(editComment, stagedComment);
                                setStagedComment(undefined);
                                setEditComment(undefined);
                            }}
                            data-testid={`comment-edit-${index}`}
                        />
                    ) : (
                        <CommentLexical
                            key={`commentLexical-${onlyComment.id}`}
                            namespace={onlyComment.id}
                            lexical={onlyComment.commentLexical}
                            maxContentHight={100}
                            commentsOpen={commentsOpen}
                            data-testid={`comment-lexical-${index}`}
                        />
                    )}
                </Box>
                {pendingReply && (
                    <CreateNewCommentPlain
                        key={`commentReply-${onlyComment.id}`}
                        sx={{
                            marginTop: 1,
                            padding: 1,
                            border: "1px solid rgba(221, 221, 221, 0.5)",
                            backgroundColor: "rgba(221, 221, 221, 0.05)",
                            borderRadius: 1
                        }}
                        stagedComment={stagedComment}
                        updateStagedComment={setStagedComment}
                        onClearStagedComment={() => {
                            setStagedComment(undefined);
                            setShowReplyComment(false);
                        }}
                        onPostComment={() => {
                            onPostComment(stagedComment);
                            setStagedComment(undefined);
                            setShowReplyComment(false);
                        }}
                        data-testid={`comment-reply-${index}`}
                    />
                )}
                {(!pendingReply && enableReplies) && (
                    <Box
                        marginTop={1}
                        width="100%" 
                        display="flex" 
                        justifyContent="flex-end"
                    >
                        <Button
                            sx={{ padding: 0 }}
                            variant="outlined"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                stageCommentFromReply(onlyComment.commentType);
                            }}
                            data-testid={`comment-reply-btn-${index}`}
                        >
                            reply
                        </Button>
                    </Box>
                )}
            </Box>
        );
    }

    // This is a thread, with replies, with optional replies 
    const headComment = comments[0];
    return (
        <Box
            sx={{
                marginTop: 1,
                padding: 1,
                border: "1px solid rgba(221, 221, 221, 0.5)",
                backgroundColor: "white",
                borderRadius: 1,
                display: "block",
            }}
            data-testid={`comment-thread-${index}`}
        >
            <HighlightCommentMeta 
                key={`commentMeta-${headComment.id}`}
                commentMetadata={headComment.commentMetadata} 
            />
            <Divider 
                sx={{ 
                    marginBottom: 0.5,
                    marginTop: 1
                }} 
            />
            {comments.map(comment => (
                <>
                    <Box
                        key={`commentHeader-${comment.id}`}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                            noWrap
                        >
                            <CommentUserDisplay comment={comment} />
                        </Typography>
                        <div>
                            <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                sx={{ marginRight: 1 }}
                                noWrap
                            >
                                <CommentDateDisplay comment={comment} />
                            </Typography>
                            <CommentOptionsMenu
                                viewerUser={authedUser}
                                comment={comment}
                                takerDocumentId={takerDocumentId}
                                disableDelete={headComment.id === comment.id}
                                extraMenuItems={(authedUser.id === comment.userId) ? [
                                    {
                                        onClick: () => {
                                            setStagedComment(comment);
                                            setEditComment(comment.id);
                                            setShowReplyComment(false);
                                        },
                                        label: "Edit"
                                    }
                                ] : undefined}
                            />
                        </div>
                    </Box>
                    {(editComment === comment.id && !!stagedComment) ? (
                        <CreateNewCommentPlain
                            key={`commentEdit-${comment.id}`}
                            sx={{
                                marginTop: 1,
                                padding: 1,
                                border: "1px solid rgba(221, 221, 221, 0.5)",
                                backgroundColor: "rgba(221, 221, 221, 0.05)",
                                borderRadius: 1
                            }}
                            stagedComment={stagedComment}
                            updateStagedComment={setStagedComment}
                            onClearStagedComment={() => {
                                setStagedComment(undefined);
                                setEditComment(undefined);
                            }}
                            onPostComment={() => {
                                onUpdateComment(editComment, stagedComment);
                                setStagedComment(undefined);
                                setEditComment(undefined);
                            }}
                        />
                    ) : (
                        <CommentLexical
                            key={`commentLexical-${comment.id}`}
                            namespace={comment.id}
                            lexical={comment.commentLexical}
                            maxContentHight={100}
                            commentsOpen={commentsOpen}
                        />
                    )}
                    
                </>
            ))}
            {pendingReply && (
                <CreateNewCommentPlain
                    key={`commentCreate-${headComment.id}`}
                    sx={{
                        padding: 1,
                        border: "1px solid rgba(221, 221, 221, 0.5)",
                        backgroundColor: "rgba(221, 221, 221, 0.05)",
                        borderRadius: 1
                    }}
                    stagedComment={stagedComment}
                    updateStagedComment={setStagedComment}
                    onClearStagedComment={() => {
                        setStagedComment(undefined);
                        setShowReplyComment(false);
                    }}
                    onPostComment={() => {
                        onPostComment(stagedComment);
                        setStagedComment(undefined);
                        setShowReplyComment(false);
                    }}
                />
            )}
            {(!pendingReply && enableReplies) && (
                <Box
                    marginTop={1}
                    width="100%" 
                    display="flex" 
                    justifyContent="flex-end"
                >
                    <Button
                        sx={{ padding: 0 }}
                        variant="outlined"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            stageCommentFromReply(headComment.commentType);
                        }}
                    >
                        reply
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default memo(CommentThread);