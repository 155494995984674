import React, { useEffect, useState } from "react";
import {
    Box,
    IconButton
} from "@mui/material/";
import { Cancel, Save } from "@mui/icons-material";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../lexical/wrappers/RichTextEditor/theme";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import ToolbarPlugin from "../lexical/wrappers/RichTextEditor/ToolbarPlugin";
import TableCellResizerPlugin from "../lexical/wrappers/RichTextEditor/TableCellResizer";
import TableCellActionMenuPlugin from '../lexical/wrappers/RichTextEditor/TableActionMenuPlugin';
import SyncSummaryEditorPlugin from "./SyncSummaryEditorPlugin";

import '../lexical/wrappers/RichTextEditor/index.css';

interface EditableSummaryProps {
    keyTermName: string;
    autoSave?: boolean;
    defaultValue: string;
    importedContent?: string[];
    onChangeValue: (s: string) => void;
    readOnly: boolean;
}

const EditableSummary = ({
    keyTermName,
    autoSave,
    defaultValue,
    importedContent,
    onChangeValue,
    readOnly,
}: EditableSummaryProps) => {
    const [editedState, setEditedState] = useState(defaultValue);
    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);

    const SUPPORTED_NODES = [
        HeadingNode,
        ListNode,
        ListItemNode,
        TableNode,
        TableCellNode,
        TableRowNode
    ];

    const editing = !readOnly;

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    useEffect(() => {
        setEditedState(defaultValue);
    }, [defaultValue])

    return (
        <Box
            data-testid={`edit-summary-${keyTermName}`}
            sx={{
                width: "100%",
                display: "inline-block",
            }}
        >
            <LexicalComposer
                initialConfig={{
                    editable: editing,
                    namespace: `edit-summary-${keyTermName}`,
                    theme: EditorThemeClasses,
                    onError(error: any) {
                        console.error(error);
                    },
                    nodes: SUPPORTED_NODES
                }}
            >
                <>
                    {editing && (
                        <ToolbarPlugin hideAlign />
                    )}
                </>
                <SyncSummaryEditorPlugin 
                    triggerEdit={editing}
                    defaultValue={defaultValue}
                    importedContent={importedContent}
                />
                <RichTextPlugin
                    contentEditable={
                        <div className="editor-scroller">
                            <div className="editor" ref={onRef}>
                                <ContentEditable 
                                    className="editor-input" 
                                    style={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        minHeight: '25px'
                                    }}
                                />
                            </div>
                        </div>
                    }
                    placeholder={<div className="editor-placeholder"></div>}
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <OnChangePlugin
                    onChange={(editorState, editor) => onChangeValue(JSON.stringify(editorState.toJSON()))}
                />
                <ListPlugin />
                <HistoryPlugin />
                <AutoFocusPlugin />
                <TabIndentationPlugin />
                <HorizontalRulePlugin />
                <ClearEditorPlugin />
                <TablePlugin hasCellMerge />
                <TableCellResizerPlugin />
                <>
                    {floatingAnchorElem && (
                        <TableCellActionMenuPlugin
                            anchorElem={floatingAnchorElem}
                            cellMerge={true}
                        />
                    )}
                </>
            </LexicalComposer>
            {editing && !autoSave && (
                <IconButton
                    data-testid="save-summary-button"
                    sx={{ borderRadius: "4px" }}
                    onClick={(e) => {
                        onChangeValue(editedState);
                    }}
                    color="info"
                >
                    <Save />
                </IconButton>
            )}
            {editing && !autoSave && (
                <IconButton
                    data-testid="cancel-summary-button"
                    sx={{ borderRadius: "4px" }}
                    onClick={(e) => {
                        setEditedState(defaultValue);
                    }}
                    color="error"
                >
                    <Cancel />
                </IconButton>
            )}
        </Box>
    );
};

export default EditableSummary;