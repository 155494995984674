import React, { useCallback, useEffect, useRef, useState, memo } from "react";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../../containers/WidgetWrapper/states";
import PageEditor from "./PageEditor";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { LexicalDocument } from "../../../types/taker/documentkeyterms.generated";
import { Box, Button, ButtonGroup, Chip, Tooltip } from "@mui/material";
import { Document, useDocumentContext } from 'react-pdf';
import {
    TransformWrapper,
    TransformComponent,
    useControls
} from "react-zoom-pan-pinch";
import { elementScroll, useVirtualizer } from '@tanstack/react-virtual'
import { CenterFocusStrongRounded, CropFreeRounded, ZoomIn, ZoomOut, ZoomOutMap } from "@mui/icons-material";
import { useAreaSelection, highlightSelectionFromRect } from "./Selection";

import type { PDFPageProxy } from 'pdfjs-dist';
import type { VirtualizerOptions } from '@tanstack/react-virtual'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const canvasCache = new Map<number, HTMLCanvasElement>();

const CustomRenderedPage = ({
    pageNumber,
    height,
    width,
    targetFileUploadItemId
}: {
    pageNumber: number;
    height: number;
    width: number;
    targetFileUploadItemId?: string
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [rendered, setRendered] = useState(false);
    const docContext = useDocumentContext();

    const lastRenderTimestamp = useRef<number>(0);

    useEffect(() => {
        if (!rendered && docContext?.pdf) {
            // Check if the canvas for this pageNumber is already cached
            if (canvasCache.has(pageNumber)) {
                // Reuse the cached canvas
                const cachedCanvas = canvasCache.get(pageNumber);
                if (containerRef.current && cachedCanvas) {
                    containerRef.current.innerHTML = ''; // Clear any existing content
                    cachedCanvas.style.width = `${width}px`;
                    cachedCanvas.style.height = `${height}px`;
                    containerRef.current.appendChild(cachedCanvas); // Append the cached canvas
                }
            } else {
                // Render the page if it's not cached
                docContext.pdf.getPage(pageNumber).then((page) => renderCustomPage(page));
            }
            setRendered(true); // Mark this page as rendered
        }
    }, [pageNumber, width, height, rendered, docContext?.pdf, targetFileUploadItemId]);

    const renderCustomPage = (page: PDFPageProxy) => {
        const viewport = page.getViewport({ scale: 2 });

        // Create a custom rendering process
        // Here you can use your own canvas, SVG, or any custom DOM elements
        const canvas = document.createElement('canvas');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const context = canvas.getContext('2d');
        if (!context) {
            return;
        }

        const renderTask = page.render({
            canvasContext: context,
            viewport
        });

        // Save the timestamp for the last render
        const pageTimestamp = new Date().getTime();
        lastRenderTimestamp.current = pageTimestamp;

        // Attach the onContinue callback to the render task, only continue
        // if the timestamp of the last render is the same as the current render timestamp
        renderTask.onContinue = (cont: () => void) => {
            if (lastRenderTimestamp.current !== pageTimestamp) {
                return;
            }
            cont();
        };

        // Add the canvas to the container when the task is resolved
        renderTask.promise.then(() => {
            if (containerRef.current) {
                canvasCache.set(pageNumber, canvas);
                canvas.style.width = `${width}px`;
                canvas.style.height = `${height}px`;
                containerRef.current.appendChild(canvas);
            }
        }).catch((error) => {
            // Handle any errors that occur during rendering, e.g. displaying an error message
            // For now, just swallow the error.
        });
    };

    return (
        <div ref={containerRef} style={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden' }}></div>
    );
};

const BoxHighlighterLayer = ({
    onUpdateHighlight,
    enabled = false,
    children
}: {
    onUpdateHighlight: (selection: DOMRect | null) => void;
    enabled?: boolean;
    children: React.ReactNode;
}) => {
    const selectContainerRef = useRef<HTMLElement | null>(null);
    const selection = useAreaSelection({ container: selectContainerRef, enabled });

    useEffect(() => {
        if (selection) {
            onUpdateHighlight(selection);
        }
    }, [selection]);

    return (
        <div
            ref={(r) => {
                if (r) {
                    selectContainerRef.current = r;
                }
            }}
            style={{
                width: "100%",
                height: "100%",
                background: "transparent",
            }}
        >
            {children}
        </div>
    );
};

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
    withCredentials: true
};

interface ControlsProps {
    enableZoom: boolean;
    keysPressed: boolean;
}

const Controls = ({
    enableZoom,
    keysPressed
}: ControlsProps) => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
        <Box
            sx={{
                position: 'absolute',
                zIndex: 1
            }}
        >
            <ButtonGroup
                size="small"
                color="inherit"
                variant="text"
            >
                {enableZoom && (
                    <>
                        <Button
                            onClick={() => zoomIn()}
                        >
                            <ZoomIn />
                        </Button>
                        <Button
                            onClick={() => zoomOut()}
                        >
                            <ZoomOut />
                        </Button>
                    </>
                )}
                <Button
                    onClick={() => resetTransform()}
                >
                    <ZoomOutMap />
                </Button>
                {!keysPressed && (
                    <Tooltip title="Press and hold ctrl (windows) or cmd (mac) or shift (both) to zoom and pan!">
                        <Button id="not-pressed-zooming" >
                            <CenterFocusStrongRounded />
                        </Button>
                    </Tooltip>
                )}
                {keysPressed && (
                    <Tooltip title="Currently zooming and panning">
                        <Button id="pressed-zooming" >
                            <CropFreeRounded />
                        </Button>
                    </Tooltip>
                )}
            </ButtonGroup>
        </Box>
    );
};

const ASPECT_RATIO_SCALE_MULTIPLIER = .80;
const DOCUMENT_MAX_WIDTH = 1200;
const ACTIVATION_KEYS = ['Meta', 'Shift'];

interface PagesEditorInnerProps {
    readOnly: boolean;
    lexicalDocument: LexicalDocument;
    parentDiv: HTMLDivElement;
    documentWidth: number;
}

const PagesEditorInner = ({
    readOnly,
    lexicalDocument,
    parentDiv,
    documentWidth
}: PagesEditorInnerProps) => {
    const {
        documentKeyTermsService,
        isKeyTermsDataLoading,
        isKeyTermGroupStateDirty
    } = useKeyTermGroupState();
    const { mutateState, getState } = useWidgetState();
    const [resizeJob, setResizeJob] = useState<any>();
    const panelViewMode = getState<KeyTermsState>().panelViewMode;
    const scrollToPage = getState<KeyTermsState>().scrollToPage;
    const scrollToElementID = getState<KeyTermsState>().scrollToElementID;
    const targetFileUploadItemId = getState<KeyTermsState>().targetFileUploadItemId;
    const [isZooming, setIsZooming] = useState<boolean>(false);
    const [isPanning, setIsPanning] = useState<boolean>(false);

    const getHeight = (thisWidth: number, index: number) => {
        const meta = lexicalDocument.pageMetadata[index] as Record<string, number>;
        if (meta) {
            let ogWidth = meta['original_page_width'];
            let ogHeight = meta['original_page_height'];
            let isVersion2 = !!meta['is_version_2'];
            if (isVersion2) {
                return (ogHeight * (thisWidth / ogWidth)) + 35;
            }
            return ((ogHeight * (thisWidth / ogWidth)) / ASPECT_RATIO_SCALE_MULTIPLIER) + 35;
        }
        return 0;
    };

    const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] = useCallback((offset, canSmooth, instance) => {
        elementScroll(offset, canSmooth, instance);
    }, []);

    const rowVirtualizer = useVirtualizer({
        count: lexicalDocument.lexicalPages.length,
        getScrollElement: () => parentDiv,
        estimateSize: (index) => getHeight(documentWidth, index),
        overscan: 1,
        scrollToFn,
        scrollPaddingEnd: 1
    });

    const resizeAllHeights = useCallback(() => {
        for (let i = 0; i < lexicalDocument.lexicalPages.length; i++) {
            rowVirtualizer.resizeItem(i, getHeight(parentDiv.offsetWidth, i));
        }
    }, [lexicalDocument, rowVirtualizer, parentDiv]);

    // Resize on panel change or document width change.
    useEffect(() => {
        if (panelViewMode === 0 || panelViewMode == 2) {
            resizeAllHeights();
        }
    }, [panelViewMode, documentWidth]);

    // Resize on window resize event.
    useEffect(() => {
        const onResize = () => {
            if (resizeJob) {
                clearTimeout(resizeJob);
            }
            setResizeJob(setTimeout(() => resizeAllHeights(), 50));
        };
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    let awaitMap: { [elementId: string]: boolean } = {}
    function onElementAvailable(id: string, callback: (element: HTMLElement | null) => void, timeout?: number) {
        if (awaitMap[id]) {
            return;
        }

        if (document.getElementById(id)) {
            callback(document.getElementById(id));
            return;
        }

        const waitTimeout = timeout ? timeout : 5000;
        const waitStart = Date.now();
        awaitMap[id] = true;
        const observer = new MutationObserver(mutations => {
            const element = document.getElementById(id);
            if ((element || Date.now() - waitStart > waitTimeout)) {
                observer.disconnect();
                if (awaitMap[id]) {
                    callback(element);
                }
                awaitMap[id] = false;
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });
    }

    const SCROLL_TO_ELEMENT_PADDING: number = 0.2;
    const scrollToElement = useCallback((element: HTMLElement) => {
        const rect = element.getBoundingClientRect();
        const scrollOffset = rowVirtualizer.scrollOffset ? rowVirtualizer.scrollOffset : 0;
        const windowHeight = rowVirtualizer.scrollRect !== null ? rowVirtualizer.scrollRect.height : 0;
        const topPadding = SCROLL_TO_ELEMENT_PADDING * windowHeight;
        const deltaToElement: number = rect.top - parentDiv.getBoundingClientRect().top;
        const targetOffset: number = scrollOffset + deltaToElement - topPadding;
        rowVirtualizer.scrollToOffset(targetOffset, { behavior: rowVirtualizer.isScrolling ? 'auto' : 'smooth' });
    }, [rowVirtualizer]);

    useEffect(() => {
        if (scrollToElementID !== undefined && scrollToPage !== undefined && !isKeyTermsDataLoading && !isKeyTermGroupStateDirty) {
            let element = document.getElementById(scrollToElementID);
            if (element === null) {
                rowVirtualizer.scrollToIndex(scrollToPage, { align: 'start', behavior: 'smooth' });
            }

            onElementAvailable(scrollToElementID, (element) => {
                if (element !== null) {
                    scrollToElement(element);
                }
            });

            mutateState<KeyTermsState>({ scrollToPage: undefined, scrollToElementID: undefined });

            function globalClickListener() {
                window.removeEventListener("click", globalClickListener, false);
                mutateState<KeyTermsState>({
                    navigateHighlightElementIDs: undefined,
                    scrollToPage: undefined,
                    scrollToElementID: undefined
                });
            }

            setTimeout(() => {
                parentDiv.addEventListener("click", globalClickListener);
            }, 3000);
        }
    }, [scrollToElementID]);

    if (!lexicalDocument || lexicalDocument.lexicalPages.length === 0) {
        return (
            <div
                style={{
                    width: "100%"
                }}
            >
                <em>document is blank or not available</em>
            </div>
        );
    }

    return (
        <>
            <TransformWrapper
                wheel={{ activationKeys: ACTIVATION_KEYS }}
                panning={{ activationKeys: ACTIVATION_KEYS }}
                doubleClick={{ disabled: true }}
                onZoomStart={() => { setIsZooming(true); }}
                onZoomStop={() => { setIsZooming(false); }}
                onPanningStart={() => { setIsPanning(true); }}
                onPanningStop={() => { setIsPanning(false); }}
            >
                <Controls keysPressed={isPanning || isZooming} enableZoom={false} />
                <TransformComponent>
                    <div
                        style={{
                            height: `${rowVirtualizer.getTotalSize()}px`,
                            width: parentDiv.offsetWidth,
                            position: 'relative'
                        }}
                    >
                        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                            const meta = lexicalDocument.pageMetadata[virtualRow.index] as Record<string, number>;
                            if (meta) {
                                let ogWidth = meta['original_page_width'];
                                let ogHeight = meta['original_page_height'];
                                let isVersion2 = !!meta['is_version_2'];
                                let scale = (parentDiv.offsetWidth / ogWidth);
                                return (
                                    <>
                                        {isVersion2 && (
                                            <div
                                                key={`document-visual-${virtualRow.index}`}
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: `${virtualRow.size}px`,
                                                    transform: `translateY(${virtualRow.start}px)`,
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                }}
                                            >
                                                <CustomRenderedPage
                                                    key={`page-${virtualRow.index}-${scale}`}
                                                    pageNumber={virtualRow.index + 1}
                                                    height={ogHeight * scale}
                                                    width={parentDiv.offsetWidth}
                                                    targetFileUploadItemId={targetFileUploadItemId}
                                                />
                                            </div>
                                        )}
                                        <div
                                            key={`document-separator-${virtualRow.index}`}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: `${virtualRow.size}px`,
                                                transform: `translateY(${virtualRow.start}px)`,
                                                zIndex: 5,
                                                pointerEvents: "none",
                                                background: "rgba(0, 0, 0, 0)"
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    position: 'absolute',
                                                    width: "100%",
                                                    zIndex: 1000,
                                                    textAlign: "center",
                                                    bottom: '-5px',
                                                    left: 0,
                                                    height: '35px',
                                                }}
                                            >
                                                <Chip
                                                    label={`page ${(virtualRow.index + 1)}`}
                                                    size="small"
                                                />
                                            </Box>
                                        </div>
                                        <div
                                            key={`document-interactive-${virtualRow.index}`}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: `${virtualRow.size}px`,
                                                transform: `translateY(${virtualRow.start}px)`,
                                                zIndex: 10
                                            }}
                                        >
                                            <PageEditor
                                                readOnly={readOnly}
                                                lexicalDocumentIdentifier={lexicalDocument.identifier}
                                                pageIndex={virtualRow.index}
                                                lexicalPage={lexicalDocument.lexicalPages[virtualRow.index]}
                                                annotations={
                                                    documentKeyTermsService.getAnnotations(
                                                        lexicalDocument.identifier,
                                                        virtualRow.index
                                                    ) || []
                                                }
                                                pageScaleFactor={scale}
                                                isV2Render={isVersion2}
                                            />
                                        </div>
                                    </>
                                );
                            }
                        })}
                    </div>
                </TransformComponent>
            </TransformWrapper>
        </>
    );
}

interface PagesEditorProps {
    readOnly: boolean;
    documentWidth: number;
    lexicalDocument: LexicalDocument;
}

const PagesEditor = ({
    readOnly,
    documentWidth,
    lexicalDocument
}: PagesEditorProps) => {
    const { takerDocumentUpload } = useKeyTermGroupState();
    const { getState } = useWidgetState();
    const targetFileUploadItemId = getState<KeyTermsState>().targetFileUploadItemId;
    const [parentDiv, setParentDiv] = useState<HTMLDivElement | null>(null);

    const pdfUrl = `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/v1/file_uploads/${takerDocumentUpload?.fileUpload.id}/items/${targetFileUploadItemId}/content`;
    const realDocumentWidth = (documentWidth >= DOCUMENT_MAX_WIDTH) ? DOCUMENT_MAX_WIDTH : documentWidth;

    useEffect(
        () => canvasCache.clear(),
        [targetFileUploadItemId]
    );

    return (
        <div
            ref={(r) => setParentDiv(r)}
            style={{
                width: `${realDocumentWidth}px`,
                margin: "0 auto",
                height: "100%",
                overflowX: "hidden",
            }}
        >
            <BoxHighlighterLayer
                enabled={false}
                onUpdateHighlight={(selection: DOMRect | null) => {
                    if (!selection) {
                        return;
                    }
                    highlightSelectionFromRect(selection);
                }}
            >
                <Document
                    file={pdfUrl}
                    options={options}
                >
                    {parentDiv && (
                        <PagesEditorInner
                            readOnly={readOnly}
                            lexicalDocument={lexicalDocument}
                            parentDiv={parentDiv}
                            documentWidth={realDocumentWidth}
                        />
                    )}
                </Document>
            </BoxHighlighterLayer>
        </div>
    );
}

export default memo(PagesEditor);
