import BoltIcon from '@mui/icons-material/Bolt';
import { SxProps, Theme, Tooltip, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { keyframes } from "@emotion/react";
import { set } from 'immer/dist/internal';

const spin = keyframes`
  0% {
    opacity: 1;
    transform: rotate(0deg);
  }
  12% {
    opacity: 0.75;
    transform: rotate(45deg);
  }
  25% {
    opacity: 0.5;
    transform: rotate(90deg);
  }
  37% {
    opacity: 0.75;
    transform: rotate(135deg);
  }
  50% {
    opacity: 1;
    transform: rotate(180deg);
  }
  62% {
    opacity: 0.75;
    transform: rotate(225deg);
  }
  75% {
    opacity: 0.5;
    transform: rotate(270deg);
  }
  87% {
    opacity: 0.75;
    transform: rotate(315deg);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
`;

const RotatedIconButton = styled(IconButton)({
  color: "white",
  backgroundImage: `linear-gradient(to left, #40a6ff, rgb(112, 0, 112))`,
  animation: `${spin} 1.5s infinite ease-in`
});

interface BoltButtonProps {
  boltId: string;
  onClick?: (answerType?: string) => void;
  onClickDelay?: number;
  disableAll?: boolean;
  disablePartialDict?: Record<string, boolean>;
  disableMenu?: boolean;
  isAnswerGen?: boolean;
  isKeyTerm?: boolean;
  isReportGen?: boolean;
  isLoading?: boolean;
  tooltipText?: string;
  pop?: string;
  sx?: SxProps<Theme>;
  size?: 'small' | 'medium' | 'large';
}
export const BoltButton = ({
  boltId,
  onClick,
  onClickDelay,
  disableAll,
  disablePartialDict,
  disableMenu,
  isAnswerGen = false,
  isKeyTerm = false,
  isReportGen = false,
  isLoading = false,
  tooltipText = "Generate AI Answer",
  sx,
  size = 'medium'
}: BoltButtonProps) => {
  const [loading, setLoading] = useState(isLoading);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (loading) {
    return (
      <RotatedIconButton
        size={size}
        data-testid={boltId}
        sx={sx}
      >
        <BoltIcon />
      </RotatedIconButton>
    )
  } else if (disableMenu) {
    return (
      <Tooltip title={tooltipText} arrow>
        <span>
          <IconButton
            size={size}
            data-testid={boltId}
            disabled={!onClick || disableAll}
            onClick={() => {
              if (onClick && onClickDelay !== undefined) {
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                  onClick();
                }, onClickDelay);
              } else if (onClick) {
                onClick();
              }
            }}
            sx={sx}
          >
            <BoltIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  } else {
    let allOptions = [];
    if (isKeyTerm) {
      allOptions.push(...[
        'Generate Fresh Summary',
        'Rephrase To Sound Better',
        'Elaborate on the Summary',
        'Shorten the Summary',
        'Speak Like Yoda'
      ]);
    } else if (isAnswerGen) {
      allOptions.push(...[
        'Generate Fresh Answer',
        'Rephrase To Sound Better',
        'Elaborate On The Answer',
        'Shorten The Answer',
        'Speak Like Yoda'
      ]);
    } else if (isReportGen) {
      allOptions.push(...[
        'Rephrase To Sound Better',
        'Elaborate',
        'Shorten',
        'Speak Like Yoda'
      ]);
    }
    const isOptionDisabled = (option: string) => {
      if (disablePartialDict && disablePartialDict[option]) {
        return !!disablePartialDict[option]
      }
      return false
    }  
    return (
      <>
        <Tooltip title={tooltipText} arrow>
          <span>
            <IconButton
              size={size}
              className="bolt-button"
              disabled={!onClick || disableAll}
              data-testid={boltId}
              onClick={handleClick}
              sx={sx}
            >
              <BoltIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem data-testid="generate_ai_answer" disabled={isOptionDisabled(allOptions[0])} onClick={() => {
            if (onClick && onClickDelay !== undefined) {
              setTimeout(() => {
                setLoading(false);
                onClick('generate_ai_answer');
              }, onClickDelay);
            } else if (onClick) {
              onClick();
            }
            handleClose();
          }}>{allOptions[0]}</MenuItem>
          <MenuItem data-testid="rephrase_ai_answer" disabled onClick={() => { if (onClick) { onClick('rephrase_ai_answer'); } handleClose(); }} >{allOptions[1]}</MenuItem>
          {allOptions.slice(2, -1).map(o => (
            <MenuItem disabled>{o}</MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}