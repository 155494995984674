import React, { PropsWithChildren, useContext, useEffect, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { PageItem } from "../../data/pages";
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { signOut } from "../../redux/actionCreators/authActionCreators";
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import { useDispatch } from "../../redux/reduxUtils/functions";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { pageOption } from "../../data/pages";
import { Avatar, Menu, MenuItem, Select, Tooltip, Typography, useTheme } from "@mui/material";
import { useUserScopedAppData } from "../../containers/UserScopedAppData/UserScopedAppData";
import { User } from "../../redux/models/dataModelTypes";
import logo from "./../../assets/images/logo_icon_300Px.png";
import logoWhite from "./../../assets/images/logo_08_white_outlined.png";
import { ArrowDropDown } from "@mui/icons-material";

export const MENU_DRAWER_WIDTH = 200;
const NAV_COLOR = "#F0F4F8";
const NAV_GRADIENT = "#276EDF"

const Context = React.createContext({});

const SideNavHook = React.createContext({});

interface SideNavHookData {
    isOpen: boolean;
    setDynamicToolbar: (dynamicToolbar: JSX.Element) => void;
}

type SideNavHook = () => SideNavHookData;

export const useSideNav: SideNavHook = () => {
    return useContext(Context) as SideNavHookData;
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: MENU_DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `0px`
});

function stringAvatar(user: User) {
    let name = `${user.firstName} ${user.lastName}`;
    return {
        sx: {
            width: 32, 
            height: 32,
            bgcolor: "rgb(25, 118, 210)",
            fontSize: 16,
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<MuiAppBarProps & { open?: boolean }>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: MENU_DRAWER_WIDTH,
        width: `calc(100% - ${MENU_DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Toolbar = styled(MuiToolbar)`
    width: 100%;
    padding-left: 16px !important;
    padding-right: 16px !important;
`;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: MENU_DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface SideNavProps { }

const renderOrgValue = (user: User, selected: string) => {
    let orgName = "unknown";
    if (!selected) {
        orgName = "My Sandbox";
    } else {
        for (const om of user.organizationMemberships) {
            if (selected === om.organization.id) {
                orgName = om.organization.name;
                break;
            }
        }
    }
    return (
        <Box 
            width="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
        >
            <Box 
                overflow="hidden" 
                width={24} 
                height={24} 
                marginRight={1}
                position={"relative"}
            >
                <Box 
                    position={"absolute"}
                    component="img" 
                    src={logoWhite} 
                    top={-12}
                    left={-12}
                    width={48} 
                    height={48} 
                />
            </Box>
            {orgName}
        </Box>
    );
};

const SideNav: React.FC<PropsWithChildren<SideNavProps>> = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [menuItems, setMenuItems] = React.useState<PageItem[]>([]);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [dynamicToolbar, setDynamicToolbar] = React.useState<JSX.Element | null>(null);
    const { selectedOrgId, setSelectedOrgId } = useUserScopedAppData();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const {
        authenticated,
        user,
        isLoading
    } = useSelector((state: RootReducerType) => state.auth);

    useEffect(() => {
        let menuItems: PageItem[] = [];
        if (user) {
            if (user.roles.includes("BUILDER") || user.roles.includes("TAKER")) {
                menuItems.push(pageOption.HOME);
            }
            if (user.roles.includes("TECHNICAL")) {
                menuItems.push(pageOption.MANAGE_MODELS,);
            }
            if (user.roles.includes("SUPERADMIN")) {
                menuItems.push(pageOption.MANAGE_APP_CONFIGS);
            }
            if (user.roles.includes("ADMIN")) {
                menuItems.push(pageOption.MANAGE_USERS);
                menuItems.push(pageOption.MANAGE_ORGANIZATIONS);
            }
            if (user.roles.includes("INDEXER")) {
                menuItems.push(pageOption.INDEXED_GUIDANCE_LIST);
            }
        }
        setMenuItems(menuItems);
    }, [user]);

    let isDebugger = useMemo(() => {
        return !!user?.roles.includes('DEBUGGER');
    }, [user]);

    if (!user || isLoading || !authenticated) {
        return null
    }

    return (
        <Box sx={{ display: 'flex', height: "100vh" }}>
            <AppBar
                position="absolute"
                color="transparent"
                elevation={0}
            >
                <Toolbar 
                    sx={{
                        backgroundColor: NAV_COLOR, //  "rgb(230, 240, 249)",
                        borderBottom: "1px solid rgb(228, 228, 228, 0.9)",
                    }}
                    variant="dense"
                >
                    <Tooltip title={"Toggle Menu"}>
                        <IconButton
                            data-testid="menu-toggling-icon"
                            onClick={toggleDrawer}
                            color="primary"
                            aria-label="open drawer"
                            edge="start"
                            sx={{
                                marginLeft: 0.25,
                                marginRight: 3,
                            }}
                        >
                            <MenuIcon id="MenuIconButton" />
                        </IconButton>
                    </Tooltip>
                    <Box 
                        sx={{ 
                            flexGrow: 1,
                            alignItems: "center",
                            display: "flex"
                        }}
                    >
                        <Box 
                            component="img" 
                            src={logo} 
                            alt="Praxi" 
                            sx={{ height: "24px" }}
                        />
                        <Typography 
                            variant="h5"
                            sx={{ paddingLeft: "6px" }}
                        >
                            Praxi
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton
                            id="handleOpenUserMenu"
                            size="small"
                            onClick={handleOpenUserMenu}
                            sx={{ p: 0 }}
                            data-testid="handle-open-user-menu"
                        >
                            <Avatar {...stringAvatar(user)} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            data-testid="menu-appbar"
                        >
                            <MenuItem
                                id="userSettingsMenuItem"
                                key={pageOption.USER_SETTINGS.name}
                                onClick={() => {
                                    handleCloseUserMenu();
                                    navigate(pageOption.USER_SETTINGS.route);
                                }}
                                data-testid="user-settings-menu-item"
                            >
                                <Typography textAlign="center">
                                    User Settings
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                id="handleLogoutMenuItem"
                                onClick={() => {
                                    handleCloseUserMenu();
                                    dispatch(signOut());
                                }}
                                data-testid="handle-logout-menu-item"
                            >
                                <Typography textAlign="center">
                                    Logout
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer 
                variant="permanent" 
                open={open}
                PaperProps={{ sx: { backgroundColor: NAV_GRADIENT } }}
            >
                <List 
                    sx={{ 
                        marginTop: "48px", 
                    }}
                >
                    {menuItems.map((pi: PageItem) => (
                        pi.name === "" ? (
                            <Divider />
                        ) : (
                            <ListItem key={pi.name} disablePadding sx={{ display: 'block' }} data-testid={`list-item-${pi.name}`}>
                                <ListItemButton
                                    data-testid={pi.name}
                                    onClick={() =>
                                        //If they click log out then log them out. Do not navigate.
                                        pi.route !== "/" ? navigate(pi.route) : dispatch(signOut())
                                    }
                                    color="primary"
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                        color="inherit"
                                    >
                                        {pi.icon}
                                    </ListItemIcon>
                                    <ListItemText 
                                        color="text.secondary"
                                        primary={pi.name} 
                                        sx={{ 
                                            opacity: open ? 1 : 0,
                                            color: "white"
                                        }} 
                                    />
                                </ListItemButton>
                            </ListItem>
                        )
                    ))}
                </List>
                {open && (
                    <Box 
                        height="100%" 
                        display="flex"
                    >
                        <Box 
                            display="inline-block"
                            alignSelf="end"
                            padding={2}
                            textAlign="center"
                            width="200px"
                        >
                            {isDebugger ? (
                                <Select
                                    IconComponent={() => <ArrowDropDown htmlColor="white"/>}
                                    sx={{ border: "1px solid white" }}
                                    size="small"
                                    fullWidth
                                    displayEmpty
                                    label=""
                                    defaultValue={selectedOrgId}
                                    renderValue={(selected) => 
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, color: "white" }}>
                                            <strong>
                                                {renderOrgValue(user, selected)}
                                            </strong>
                                        </Box>
                                    }
                                    onChange={(e: any) => {
                                        setSelectedOrgId(e.target.value);
                                    }}
                                    data-testid="select-org"
                                >
                                    <MenuItem value="">
                                        Sandbox
                                    </MenuItem>
                                    {user.organizationMemberships.map((om) =>
                                        <MenuItem value={om.organization.id}>
                                            {om.organization.name}
                                        </MenuItem>
                                    )}
                                </Select>
                            ) : (
                                <Typography variant="h6" color="white">
                                    {renderOrgValue(user, selectedOrgId)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                )}
            </Drawer>
            <Box sx={{ flexGrow: 1 }}>
                <Box 
                    component="main" 
                    sx={{ 
                        marginTop: "48px",
                        height: "calc(100vh - 48px);", 
                        width: open ? "calc(100vw - 200px)" : "100vw",
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen
                        })
                    }}
                >
                    <Context.Provider
                        value={{
                            isOpen: open,
                            setDynamicToolbar
                        }}
                    >
                        {children}
                    </Context.Provider>
                </Box>
            </Box>
        </Box>
    );
}

export default memo(SideNav);