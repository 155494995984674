import { KeyTerm } from "../../../types/taker/documentkeyterms.generated";
import EditableSummary from "../../../components/form/EditableSummary";
import { Card, CardContent, Divider, Typography } from "@mui/material";

interface CurrentInputWidgetProps {
    keyTerm: KeyTerm;
    editedSummary?: string;
    setEditedSummary?: (s: string) => void;
    importedContent?: string[];
}
export const CurrentInputWidget = ({ keyTerm, editedSummary, setEditedSummary, importedContent }: CurrentInputWidgetProps) => {
    return (
        <Card variant="outlined" sx={{ height: "100%", overflowY: "auto" }}>
            <CardContent>
                <Typography variant="subtitle1">
                    Current Input
                </Typography>
                <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                <EditableSummary
                    data-testid={`key-term-summary-${keyTerm.termName}`}
                    keyTermName={keyTerm.termName}
                    defaultValue={editedSummary ? editedSummary : keyTerm.summary}
                    importedContent={importedContent}
                    onChangeValue={(v) => {
                        if (setEditedSummary) {
                            setEditedSummary(v)
                        }
                    }}
                    autoSave={true}
                    readOnly={false}
                />
            </CardContent>
        </Card>
    )
}