import React, { memo, useMemo, useState } from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import KeyTermGroupState, { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';
import { TakerDocumentUpload } from '../../../../redux/models/dataModelTypes';
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../../../../components/lexical/wrappers/RichTextEditor/theme";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import TableCellResizerPlugin from "../../../../components/lexical/wrappers/RichTextEditor/TableCellResizer";
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { $insertFirst, $insertNodeToNearestRoot } from '@lexical/utils';

import '../../../../components/lexical/wrappers/RichTextEditor/index.css';

const KeyTermsList = () => {
    const {
        documentKeyTermsService,
        lastSavedTimestamp
    } = useKeyTermGroupState();

    const allKeyTerms = useMemo(() =>
        documentKeyTermsService?.keyTerms || [],
        [lastSavedTimestamp, documentKeyTermsService]
    );

    const SUPPORTED_NODES = [
        HeadingNode,
        ListNode,
        ListItemNode,
        TableNode,
        TableCellNode,
        TableRowNode
    ];

    return (
        <Box paddingTop={1}>
            <Stack>
                {allKeyTerms.map(kt => (
                    <>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                        >
                            <u>{kt.termName}</u>
                        </Typography>
                        <Box paddingBottom={1}>
                            <LexicalComposer
                                initialConfig={{
                                    editable: false,
                                    namespace: `edit-summary-${kt.termName}`,
                                    theme: EditorThemeClasses,
                                    onError(error: any) {
                                        console.error(error);
                                    },
                                    nodes: SUPPORTED_NODES,
                                    editorState: (editor) => {
                                        const isEmpty = kt.summary === "" || (kt.summary === null || kt.summary === undefined);
                                        if (!isEmpty) {
                                            try {
                                                const editorState = editor.parseEditorState(kt.summary);
                                                if (!editorState.isEmpty()) {
                                                    editor.setEditorState(editorState);
                                                }
                                            } catch (e) {
                                                editor.update(
                                                    () => {
                                                        const root = $getRoot();
                                                        root.clear();
                                                        const paragraph = $createParagraphNode();
                                                        $insertFirst(paragraph, $createTextNode(kt.summary));
                                                        $insertNodeToNearestRoot(paragraph);
                                                    }
                                                );
                                            }

                                        }
                                    }
                                }}
                            >
                                <RichTextPlugin
                                    contentEditable={
                                        <div className="editor-scroller">
                                            <div className="editor">
                                                <ContentEditable
                                                    className="editor-input"
                                                    style={{
                                                        paddingTop: '0px',
                                                        paddingBottom: '0px',
                                                        minHeight: '25px'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    placeholder={<div className="editor-placeholder"></div>}
                                    ErrorBoundary={LexicalErrorBoundary}
                                />
                                <ListPlugin />
                                <HistoryPlugin />
                                <AutoFocusPlugin />
                                <TabIndentationPlugin />
                                <HorizontalRulePlugin />
                                <ClearEditorPlugin />
                                <TablePlugin hasCellMerge />
                                <TableCellResizerPlugin />
                            </LexicalComposer>
                        </Box>
                    </>
                ))}
            </Stack>
        </Box>
    );
};

interface ResearchKeyTermsProps {
}

const ResearchKeyTerms = ({
}: ResearchKeyTermsProps) => {
    const [keyTermGroupId, setKeyTermGroupId] = useState<string | undefined>();
    const { activeTakerDocument } = useTakerState();

    const allKeyTermGroups = useMemo(() =>
        activeTakerDocument?.takerDocumentUploads || [],
        [activeTakerDocument]
    );

    return (
        <Stack>
            <Stack direction="row" spacing={1}>
                <FormControl sx={{ minWidth: 120 }}>
                    <Select
                        size="small"
                        displayEmpty
                        data-testid="key-term-group-name-select"
                        value={keyTermGroupId}
                        onChange={(event: SelectChangeEvent<string>) => {
                            setKeyTermGroupId(event.target.value);
                        }}
                        label=""
                    >
                        <MenuItem value={undefined}>
                            Select a Group
                        </MenuItem>
                        {allKeyTermGroups.map((ktg: TakerDocumentUpload) => (
                            <MenuItem value={ktg.id}>
                                {ktg.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <Box position="relative">
                {keyTermGroupId && (
                    <KeyTermGroupState takerDocumentUploadId={keyTermGroupId}>
                        <KeyTermsList />
                    </KeyTermGroupState>
                )}
            </Box>
        </Stack>
    );
}

export default memo(ResearchKeyTerms);