import React, { useMemo } from "react";
import { Box, Typography, styled } from "@mui/material/";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../../containers/WidgetWrapper/states";
import { DocumentHighlight } from "../../../types/taker/documentkeyterms.generated";

interface HighlightCommentMetaProps {
    commentMetadata: Record<string, any>;
}

const BoxWithHoverBg = styled(Box)(({ theme }) => ({
    background: "none",
    '&:hover': {
        background: "rgba(0, 0, 0, 0.03)",
        cursor: "pointer"
    }
}));

/**
 * HighlightCommentMeta is a component that displays the metadata for a comment
 * on a highlight in a document. The metadata includes the text content of the
 * highlight, the group name of the document, the name of the document, and the
 * page number of the highlight.
 *
 * The component uses the Tooltip component from Material UI to display the
 * metadata on hover. The text content of the highlight is displayed in a
 * secondary color.
 *
 * @param {HighlightCommentMetaProps} props the props for the component
 * @returns {JSX.Element} the component
 */
function HighlightCommentMeta({ commentMetadata }: HighlightCommentMetaProps) {
    const {
        takerDocumentUploads
    } = useTakerState();
    const { mutateState } = useWidgetState();
    const {
        highlightTextContent,
        lexicalDocumentIdentifier,
        pageIndex,
        documentHighlights
    } = commentMetadata;

    let targetGroupName = null;
    let targetDocumentName = null;
    if (takerDocumentUploads) {
        for (const { name: groupName, fileUpload } of takerDocumentUploads) {
            for (const { id, label: fileName } of fileUpload.fileUploadItems) {
                if (id === lexicalDocumentIdentifier) {
                    targetGroupName = groupName;
                    targetDocumentName = fileName;
                    break;
                }
            }
        }
    }

    const firstElementID = useMemo(() => {
        const typedHighlights = documentHighlights as DocumentHighlight[];
        if (typedHighlights && typedHighlights.length > 0) {
            return typedHighlights[0].elementId;
        }
    }, [documentHighlights]);

    const highlightGroupDocumentTitle = `${targetGroupName} - ${targetDocumentName} - Page ${pageIndex + 1}`;
    return (
        <BoxWithHoverBg
            width="100%"
            onClick={() => mutateState<KeyTermsState>({
                scrollToPage: pageIndex,
                scrollToElementID: firstElementID,
                navigateHighlightElementIDs: documentHighlights,
                targetFileUploadItemId: lexicalDocumentIdentifier
            })}
        >
            {(targetGroupName && targetDocumentName) && (
                <Typography
                    variant="body2"
                    color="text.primary"
                    noWrap
                    data-testid="comments-drawer-group-doc-title"
                >
                    <strong>{highlightGroupDocumentTitle}</strong>
                </Typography>
            )}
            <Typography
                variant="body2"
                color="text.secondary"
                noWrap
                data-testid="comments-drawer-highlight-text"
            >
                {highlightTextContent}
            </Typography>
        </BoxWithHoverBg>
    );
}

export default HighlightCommentMeta;
