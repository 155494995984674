import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab
} from "@mui/material/";
import SideNav from "../../components/navigation/SideNav";
import { Assistant, AssistantAlgorithmParam, AssistantCapabilities, LanguageModelPrompt } from "../../redux/models/dataModelTypes";
import { TextFieldRhf } from "../../components/form/reactHookForm/textFieldRhf";
import { SwitchRhf } from "../../components/form/reactHookForm/switchRhf";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import FullConfig, { ConfigEntry } from "../../components/form/EntryBuilder";
import { useAddAlgoParamMutation, useAddPromptMutation, useDeleteAlgoParamMutation, useDeletePromptMutation, useGetAssistantQuery, useUpdateAssistantCapabilitiesMutation, useUpdateAssistantMutation } from "../../redux/services/assistant";


const getOptionsForAlgoParams = (capabilities: AssistantCapabilities) => {
    let allOptions = []
    if (capabilities.canAnnotateDocuments) {
        allOptions.push(...[
            {
                display: `Match key term example`, 
                id: `match_key_term_example`,
                description: `
                Whether or not to filter examples by the key term label.
                `
            },
            {
                display: `Max Number of Key Kerm Examples`, 
                id: `max_key_term_examples`,
                description: `
                Maximum number of examples to include in the prompt.
                `
            },
            {
                display: `Tagging Examples`, 
                id: `tagging_examples`,
                isJson: true,
                description: `
                formatted like the following
                [

                    {
                        "key_term_label": <label>
                        "key_term_description": <description>,
                        "content": [
                            <section html>,
                            ...
                        ],
                        "output": [   
                            {    
                                "key_term_name": "name of first extracted key term",     
                                "ids": [id1, id2, ...],     
                                "key_term_summary": "summary of first extracted key term"    
                            },   
                            {     
                                "key_term_name": "name of second extracted key term",      
                                "ids": [id1, id2, ...],     
                                "key_term_summary": "summary of second extracted key term"   
                            },   
                            ... 
                        ] 
                    }
                ]
                `
            },
        ]);
    } 
    if (capabilities.canSummarize) {
        allOptions.push(...[
            {
                display: `Match example`, 
                id: `match_example`,
                description: `
                Whether or not to filter examples by the key term label.
                `
            },
            {
                display: `Max Number of Examples`, 
                id: `max_examples`,
                description: `
                Maximum number of examples to include in the prompt.
                `
            },
            {
                display: `Summary with Category Examples`, 
                id: `summary_with_category_examples`,
                isJson: true,
                description: `
                formatted like the following: TODO
                `
            },
            {
                display: `Summary without Category Examples`, 
                id: `summary_without_category_examples`,
                isJson: true,
                description: `
                formatted like the following: TODO
                `
            }
        ]);
    }
    return allOptions;
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


interface AssistantUpdateFormProps {
  assistant: Assistant;
}

const AssistantUpdateForm = ({ assistant }: AssistantUpdateFormProps) => {
    const [updateAssistant, updateAssistantResult] = useUpdateAssistantMutation();
    const [updateAssistantCapabilities, updateAssistantCapabilitiesResult] = useUpdateAssistantCapabilitiesMutation();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(yup.object().shape({
            name: yup.string().required(),
            description: yup.string().required(),
            canChat: yup.boolean().required(),
            canSummarize: yup.boolean().required(),
            canQa: yup.boolean().required(),
            canTableQa: yup.boolean().required(),
            canExpandTransformReport: yup.boolean().required(),
            canOutlineTransformReport: yup.boolean().required(),
            canAnnotateDocuments: yup.boolean().required(),
            canSimulateFulfillment: yup.boolean().required()
        })),
        defaultValues: {
            name: assistant.name,
            description: assistant.description,
            canChat: assistant.capabilities.canChat,
            canSummarize: assistant.capabilities.canSummarize,
            canQa: assistant.capabilities.canQa,
            canTableQa: assistant.capabilities.canTableQa,
            canExpandTransformReport: assistant.capabilities.canExpandTransformReport,
            canOutlineTransformReport: assistant.capabilities.canOutlineTransformReport,
            canAnnotateDocuments: assistant.capabilities.canAnnotateDocuments,
            canSimulateFulfillment: assistant.capabilities.canSimulateFulfillment
        },
    });

    const handleSubmitCust = (d: any) => {
        updateAssistant({
            id: assistant.id,
            name: d.name,
            description: d.description
        });
        if (d.canChat !== assistant.capabilities.canChat 
            || d.canSummarize !== assistant.capabilities.canSummarize
            || d.canQa !== assistant.capabilities.canQa
            || d.canTableQa !== assistant.capabilities.canTableQa
            || d.canExpandTransformReport !== assistant.capabilities.canExpandTransformReport
            || d.canOutlineTransformReport !== assistant.capabilities.canOutlineTransformReport
            || d.canAnnotateDocuments !== assistant.capabilities.canAnnotateDocuments
            || d.canSimulateFulfillment !== assistant.capabilities.canSimulateFulfillment
        ) {
            updateAssistantCapabilities({
                id: assistant.capabilities.id,
                assistantId: assistant.id,
                canChat: d.canChat, 
                canSummarize: d.canSummarize, 
                canQa: d.canQa, 
                canTableQa: d.canTableQa, 
                canExpandTransformReport: d.canExpandTransformReport,
                canOutlineTransformReport: d.canOutlineTransformReport,
                canAnnotateDocuments: d.canAnnotateDocuments,
                canSimulateFulfillment: d.canSimulateFulfillment
            });
        }
    };

    return (
        <form 
            onSubmit={handleSubmit(handleSubmitCust)}
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <TextFieldRhf
                sx={{ marginTop: "20px" }}
                label="Name"
                fullWidth={false}
                fieldName="name"
                variant="outlined"
                control={control}
                errors={errors}
            />
            <TextFieldRhf
                sx={{ marginTop: "20px" }}
                label="Description"
                multiline={true}
                fieldName="description"
                variant="outlined"
                control={control}
                errors={errors}
            />
            <Box sx={{ marginTop: "20px" }}>
                <Typography variant="h6">
                    Capabilities
                </Typography>
            </Box>
            <SwitchRhf
                sx={{ marginTop: "20px" }}
                label="Chat"
                fieldName="canChat"
                disabled
                control={control}
            />
            <SwitchRhf
                sx={{ marginTop: "20px" }}
                label="Summarize"
                fieldName="canSummarize"
                control={control}
            />
            <SwitchRhf
                sx={{ marginTop: "20px" }}
                label="QA"
                fieldName="canQa"
                control={control}
            />
            <SwitchRhf
                sx={{ marginTop: "20px" }}
                label="Table QA (question groups)"
                fieldName="canTableQa"
                control={control}
            />
            <SwitchRhf
                sx={{ marginTop: "20px" }}
                label="Memo Expand Report"
                fieldName="canExpandTransformReport"
                control={control}
            />
            <SwitchRhf
                sx={{ marginTop: "20px" }}
                label="Memo Outline Report"
                fieldName="canOutlineTransformReport"
                control={control}
            />
            <SwitchRhf
                sx={{ marginTop: "20px" }}
                label="Document Tagging"
                fieldName="canAnnotateDocuments"
                control={control}
            />
            <SwitchRhf
                sx={{ marginTop: "20px" }}
                label="Can Simulate Fulfillment"
                fieldName="canSimulateFulfillment"
                control={control}
            />
            <Box sx={{ marginTop: "20px" }}>
                <Button 
                    variant="contained" 
                    type="submit"
                >
                    Save
                </Button>
            </Box>
        </form>
    );
}

const AssistantPage = () => {
    const { id } = useParams<{ id: any }>();
    const [tabValue, setTabValue] = useState(0);

    const { data: assistant } = useGetAssistantQuery(id);
    const [addAlgoParam, addAlgoParamResult] = useAddAlgoParamMutation();
    const [deleteAlgoParam, deleteAlgoParamResult] = useDeleteAlgoParamMutation();
    const [addPrompt, addPromptResult] = useAddPromptMutation();
    const [deletePrompt, deletePromptResult] = useDeletePromptMutation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

    return (
        <SideNav>
            <Box 
                padding={1}
                height="100%"
                overflow="auto"
            >
                {assistant && (
                    <Box>
                        <Typography variant="h5">{`Based on ${assistant.languageModel.name}`}</Typography>
                        <Typography paragraph>
                            {assistant.languageModel.description}
                        </Typography>
                    </Box>
                )}
                <Tabs 
                value={tabValue} 
                onChange={handleChange} 
                aria-label="basic tabs example"
                >
                    <Tab label="Properties and Capabilities" {...a11yProps(0)} />
                    <Tab label="Algorithm Parameters" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    {(assistant && assistant.capabilities) && (
                        <AssistantUpdateForm assistant={assistant}/>
                    )}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {(assistant) && (
                        <FullConfig
                            options={getOptionsForAlgoParams(
                                assistant.capabilities
                            )}
                            additionalFields={[]}
                            entries={
                                assistant.algorithmParams.map((ap: AssistantAlgorithmParam) => ({
                                    id: ap.id,
                                    key: ap.key,
                                    rawData: ap.value,
                                    formattedData: ap.value
                                } as ConfigEntry))
                            }
                            onDeleteEntry={(ce: ConfigEntry) => {
                                if (assistant) {
                                    deleteAlgoParam({
                                        id: ce.id,
                                        assistantId: assistant.id
                                    });
                                }
                            }}
                            keyOptionsGetter={(k: string) => undefined}
                            onCreate={(d: any) => {
                                if (assistant) {
                                    addAlgoParam({
                                        assistantId: assistant.id,
                                        key: d.key,
                                        value: d.data
                                    });
                                }
                            }}
                        />
                    )}
                </TabPanel>
            </Box>
        </SideNav>
    );
}

export default AssistantPage;
