import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';
import { Button, Checkbox, Divider, ListItemButton, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { useCounter, useLocalStorage } from '@uidotdev/usehooks';
import { useWidgetState } from '../../../containers/WidgetWrapper/wrapper';
import { KeyTermsState } from '../../../containers/WidgetWrapper/states';
import { CommentType } from '../../../redux/models/dataModelTypes';

const DOCUMENT_COMMENT_VALUE = "DOCUMENT_HIGHLIGHT";
const KEY_TERM_COMMENT_VALUE = "KEY_TERM_GROUP";
const ANALYSIS_COMMENT_VALUE = "ANALYSIS";
const REPORT_COMMENT_VALUE = "REPORT";

export type Filter = {
    // Type + value will create a unique filter.
    // The type and value will also determine how the filter is applied.
    type: "COMMENT_TYPE" | "KEY_TERM_GROUP" | "DOCUMENT";
    value: string;
    label: string;
    enabled: boolean;
    disabledForUse: boolean;
    hasDivider?: boolean;
};

export interface CommentFiltersProps {
    commentType?: CommentType;
    onChangeEnabledFilters: (filters: Filter[]) => void;
};

/**
 * CommentFilters is a component that renders a menu of filters for a comments drawer.
 * The filters are divided into three categories: document, key term group, and document.
 * The filters are applied to the comments drawer based on the user's selection.
 * The component also handles saving the user's filter selection to local storage.
 * 
 * @param {{ commentType?: CommentType; onChangeEnabledFilters: (filters: Filter[]) => void; }} props
 * @prop {CommentType} [commentType] The type of comment to filter by.
 * @prop {(filters: Filter[]) => void} onChangeEnabledFilters A callback that will be called when the enabled filters change.
 * 
 * @returns {JSX.Element} The CommentFilters component.
 */
const CommentFilters = ({
    commentType,
    onChangeEnabledFilters
}: CommentFiltersProps) => {
    const {
        takerDocumentId,
        takerDocumentUploads
    } = useTakerState();
    const { getState } = useWidgetState();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [savedFilters, setSavedFilters] = useLocalStorage<Record<string, boolean>>(`${takerDocumentId}-commentDrawerFilters`, {});
    const [availableFilters, setAvailableFilters] = useState<Filter[]>([]);
    const [count, countMutator] = useCounter(0);
    const openMenu = Boolean(anchorEl);

    const targetFileUploadItemId = getState<KeyTermsState>().targetFileUploadItemId;

    const targetKeyTermGroupId = useMemo(() => {
        if (!targetFileUploadItemId || !takerDocumentUploads) {
            return;
        }
        return takerDocumentUploads.find(
            tdu => tdu.fileUpload.fileUploadItems.some(
                fui => fui.id === targetFileUploadItemId
            )
        )?.id;
    }, [takerDocumentUploads, targetFileUploadItemId]);
    
    const applySavedFilterOrDefault = useCallback((filter: Partial<Filter>, defaultEnabled: boolean) => {
        const key = `${filter.type}-${filter.value}`;
        filter.enabled = savedFilters[key] ?? defaultEnabled;
        return filter as Filter;
    }, [savedFilters]);

    const isFilterEnabled = (
        filterType: "COMMENT_TYPE" | "KEY_TERM_GROUP" | "DOCUMENT", 
        filterValue: string, 
        filters: Filter[]
    ) => {
        for (const filter of filters) {
            if (filter.type === filterType && filter.value === filterValue) {
                return filter.enabled;
            }
        }
        return false;
    };

    // All available filters.
    useEffect(() => {
        const filters: Filter[] = [];

        // Build all of the filters here.
        // Use saved filter values to override defaults.
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: DOCUMENT_COMMENT_VALUE,
                label: "Documents",
                disabledForUse: false
            }, 
            (commentType === "DOCUMENT_HIGHLIGHT")
        ));
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: KEY_TERM_COMMENT_VALUE,
                label: "Key Terms",
                enabled: false,
                disabledForUse: true
            },
            false
        ));
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: ANALYSIS_COMMENT_VALUE,
                label: "Analysis",
                enabled: false,
                disabledForUse: true
            },
            false
        ));
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: REPORT_COMMENT_VALUE,
                label: "Report",
                enabled: false,
                disabledForUse: true,
                hasDivider: true
            },
            false
        ));

        // These filters are only applicable under certain conditions.
        const documentFiltersEnabled = isFilterEnabled("COMMENT_TYPE", DOCUMENT_COMMENT_VALUE, filters);
        const keyTermFiltersEnabled = isFilterEnabled("COMMENT_TYPE", KEY_TERM_COMMENT_VALUE, filters);

        if (documentFiltersEnabled || keyTermFiltersEnabled) {
            if (takerDocumentUploads) {
                for (const takerDocumentUpload of takerDocumentUploads) {
                    filters.push(applySavedFilterOrDefault(
                        {
                            type: "KEY_TERM_GROUP",
                            value: takerDocumentUpload.id,
                            label: `Key Term Group - ${takerDocumentUpload.name}`,
                            disabledForUse: false
                        },
                        (targetKeyTermGroupId === takerDocumentUpload.id)
                    ));

                    const tduFilterEnabled = isFilterEnabled("KEY_TERM_GROUP", takerDocumentUpload.id, filters);
                    if (documentFiltersEnabled && tduFilterEnabled) {
                        for (const fileItem of takerDocumentUpload.fileUpload.fileUploadItems) {
                            if (fileItem.state !== "ARCHIVED") {
                                let rawLabel = `Document - ${fileItem.label}`;
                                if (rawLabel.length > 35) {
                                    rawLabel = rawLabel.substring(0, 35) + "...";
                                }
                                filters.push(applySavedFilterOrDefault(
                                    {
                                        type: "DOCUMENT",
                                        value: fileItem.id,
                                        label: rawLabel,
                                        disabledForUse: false
                                    }, 
                                    (targetFileUploadItemId === fileItem.id)
                                ));
                            }
                        }
                    }
                }
            }
        }
        setAvailableFilters(filters);
    }, [
        commentType,
        targetFileUploadItemId,
        targetKeyTermGroupId,
        takerDocumentUploads,
        count
    ]);

    // Relay enabled filters to parent
    useEffect(() => {
        onChangeEnabledFilters(availableFilters.filter(f => f.enabled));
    }, [availableFilters]);

    return (
        <>
            <Button
                data-testid="comments-drawer-filters-button"
                variant='outlined'
                startIcon={<FilterList />}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setAnchorEl(event.currentTarget);
                }}
            >
                filters
            </Button>
            <Menu
                data-testid="comments-drawer-filters-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuList dense>
                    {availableFilters.map((f, filterIndex ) => (
                        <>
                            <MenuItem 
                                data-testid={`comments-drawer-filters-menuitem-${f.type}-${filterIndex}`}
                                disabled={f.disabledForUse} 
                                key={f.label}
                            >
                                    <Checkbox
                                        data-testid={`comments-drawer-filters-checkbox-${f.type}-${filterIndex}`}
                                        checked={f.enabled}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            let newSavedFilters = {...savedFilters};
                                            newSavedFilters[`${f.type}-${f.value}`] = event.target.checked;
                                            setSavedFilters(newSavedFilters);
                                            countMutator.increment();
                                        }}
                                    />
                                <ListItemText secondary={f.label}  />
                            </MenuItem>
                            {f.hasDivider && (
                                <Divider />
                            )}
                        </>
                    ))}
                </MenuList>
            </Menu>
        </>
    );
}

export default memo(CommentFilters);