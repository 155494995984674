import React, { useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material/";
import SideNav from "../../components/navigation/SideNav";
import TakerDocumentContainer from "./../../containers/TakerDocumentState/TakerDocumentState";
import ReadOnlyBuilderDataContainer from "./../../containers/ReadOnlyBuilderData/ReadOnlyBuilderData";
import { useGetTakerQuery } from "../../redux/services/taker";
import { useSelector } from "../../redux/reduxUtils/functions";
import { RootReducerType } from "../../redux/models/reduxTypes";
import ReportStateContainer from "../../containers/TakerDocumentState/ReportState";
import KeyTermGroupState from "../../containers/TakerDocumentState/KeyTermGroupState";
import { useLocalStorage } from "@uidotdev/usehooks";


const Taker = () => {
  const { id } = useParams<{ id: any }>();
  const [selectedTtduId] = useLocalStorage<string | null>(`${id}-ttduId`, null);

  const {
    data: taker
  } = useGetTakerQuery(id);
  const { user } = useSelector((state: RootReducerType) => state.auth);

  const activeTakerDocument = useMemo(() => {
    if (!taker) {
      return null;
    }
    const takerDocuments = taker.takerDocuments;
    if (!takerDocuments || takerDocuments.length === 0) {
      return null;
    }
    return takerDocuments[0];
  }, [taker]);

  const builderDocumentId = useMemo(() => {
    if (!activeTakerDocument) {
      return null;
    }
    return activeTakerDocument.builderDocumentId;
  }, [activeTakerDocument]);

  return (
    <SideNav>
      {(!taker || !user || !activeTakerDocument || !builderDocumentId) ? (
        <CircularProgress />
      ) : (
        <ReadOnlyBuilderDataContainer builderDocumentId={builderDocumentId}>
          <TakerDocumentContainer
            originalTaker={taker}
            userId={user.id}
            takerDocumentId={activeTakerDocument.id}
          >
            <ReportStateContainer>
              <KeyTermGroupState takerDocumentUploadId={selectedTtduId}>
                <Outlet />
              </KeyTermGroupState>
            </ReportStateContainer>
          </TakerDocumentContainer>
        </ReadOnlyBuilderDataContainer>
      )}
    </SideNav>
  );
}

export default Taker;
