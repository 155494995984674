import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  LexicalCommand,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { AddAnnotationInputBox } from './AddAnnotationInputBox';
import { DocumentAnnotation, DocumentHighlight } from '../../../../types/taker/documentkeyterms.generated';
import { RawHtmlNode } from '../../nodes/RawHtmlNode';
import { createRectsFromDOMRange } from '@lexical/selection';
import { getDOMRangeRect } from '../common';
import { useCommentsDrawer } from '../../../taker/CommentsDrawer';

import './index.css';
import { useWidgetState } from '../../../../containers/WidgetWrapper/wrapper';
import { KeyTermsState } from "../../../../containers/WidgetWrapper/states";

export interface AddHighlightCommandPayload {
  documentHighlights: DocumentHighlight[];
};

export const INSERT_INLINE_COMMAND: LexicalCommand<AddHighlightCommandPayload> = createCommand(
  'INSERT_INLINE_COMMAND',
);

interface AnnotationPluginProps {
  annotations: DocumentAnnotation[];
  onAddAnnotation: (tns: string[], a: DocumentAnnotation) => void;
  lexicalDocumentIdentifier: string;
  pageIndex: number;
  pageScaleFactor: number;
  editorParentBoundingBox?: DOMRect;
}

export default function AnnotationPlugin({
  annotations,
  onAddAnnotation,
  lexicalDocumentIdentifier,
  pageIndex,
  pageScaleFactor,
  editorParentBoundingBox
}: AnnotationPluginProps): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [showAddAnnotationInput, setShowAddAnnotationInput] = useState(false);
  const [currentPayload, setCurrentPayload] = useState<AddHighlightCommandPayload>();
  const [originalRangeRect, setOriginalRangeRect] = useState<DOMRect>();
  const [originalSelectionRects, setOriginalSelectionRects] = useState<ClientRect[]>(); 
  const { getState } = useWidgetState();
  const navigateHighlightElementIDs = getState<KeyTermsState>().navigateHighlightElementIDs;
  const { highlightsPerDocumentPage } = useCommentsDrawer();

  const submitAddAnnotation = useCallback(
    (
      keyTermNames: string[],
      lexicalDocumentIdentifier: string
    ) => {
      if (currentPayload) {
        let documentAnnotation: DocumentAnnotation = {
          annotationId: window.crypto.randomUUID(),
          lexicalDocumentIdentifier,
          documentHighlights: currentPayload.documentHighlights,
          page: pageIndex
        }
        onAddAnnotation(keyTermNames, documentAnnotation);
      }
      setShowAddAnnotationInput(false);
    }, 
    [currentPayload]
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        INSERT_INLINE_COMMAND,
        (payload) => {
          const domSelection = window.getSelection();
          const rootElement = editor.getRootElement();
          if (domSelection !== null && rootElement !== null) {
            const rangeRect = getDOMRangeRect(domSelection, rootElement);
            const selectionRects = Array.from(domSelection.getRangeAt(0).getClientRects());
            setOriginalRangeRect(rangeRect);
            setOriginalSelectionRects(selectionRects);
          }
          setShowAddAnnotationInput(true);
          setCurrentPayload(payload);
  
          if (domSelection !== null) {
            domSelection.removeAllRanges();
          }
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerNodeTransform(RawHtmlNode, (rawHtmlNode) => {
        // Synchronize this node's metadata to the outside state.
        if (highlightsPerDocumentPage[lexicalDocumentIdentifier]) {
          rawHtmlNode.__metadata["commentDocumentHighlights"] = highlightsPerDocumentPage[lexicalDocumentIdentifier][pageIndex] || [];
        }
        rawHtmlNode.__metadata["documentHighlights"] = annotations.flatMap(a => a.documentHighlights || []);
        rawHtmlNode.__metadata["pageScaleFactor"] = pageScaleFactor;
        rawHtmlNode.__metadata["navigateHighlightElementIDs"] = navigateHighlightElementIDs;
      })
    );
  }, [
    editor,
    annotations,
    pageScaleFactor,
    navigateHighlightElementIDs,
    highlightsPerDocumentPage
  ]);

  return (
    <>
      {showAddAnnotationInput &&
        createPortal(
          <AddAnnotationInputBox
            editor={editor}
            cancelAddAnnotation={() => setShowAddAnnotationInput(false)}
            submitAddAnnotation={submitAddAnnotation}
            originalRangeRect={originalRangeRect}
            originalSelectionRects={originalSelectionRects}
            editorParentBoundingBox={editorParentBoundingBox}
          />,
          document.body,
        )}
    </>
  );
}