import React, { Suspense } from 'react';
import {
    $applyNodeReplacement,
    DOMExportOutput,
    DecoratorNode,
    EditorConfig,
    LexicalNode,
    NodeKey,
    SerializedLexicalNode,
    Spread,
} from 'lexical';
import RawHtmlComponentV1 from './RawHtmlComponentV1';
import RawHtmlComponentV2 from './RawHtmlComponentV2';

export interface RawHtmlPayload {
    rawHtml: string;
    page: number;
    pageWidth: number;
    version: number;
}

export type SerializedRawHtmlNode = Spread<
    {
        rawHtml: string;
        page: number;
        pageWidth: number;
        version: number;
    },
    SerializedLexicalNode
>;

export class RawHtmlNode extends DecoratorNode<JSX.Element> {
    __rawHtml: string;
    __page: number;
    __pageWidth: number;
    __version: number;

    /** @internal */
    __metadata: Record<string, any>;

    static override getType(): string {
        return 'raw-html-node';
    }

    static override clone(node: RawHtmlNode): RawHtmlNode {
        return new RawHtmlNode(
            node.__rawHtml,
            node.__page,
            node.__pageWidth,
            node.__version
        );
    }

    static override importJSON(serializedNode: SerializedRawHtmlNode): RawHtmlNode {
        const node = $createRawHtmlNode({
            rawHtml: serializedNode.rawHtml,
            page: serializedNode.page,
            pageWidth: serializedNode.pageWidth,
            version: serializedNode.version
        });
        return node;
    }

    override exportJSON(): SerializedRawHtmlNode {
        return {
            rawHtml: this.__rawHtml,
            page: this.__page,
            pageWidth: this.__pageWidth,
            version: this.__version,
            type: "raw-html-node"
        };
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('div');
        element.setAttribute('description', "cannot render");
        return { element };
    }

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement('span');
        return span;
    }

    updateDOM(): false {
        return false;
    }

    getMetadata() {
        return this.__metadata;
    }

    constructor(
        rawHtml: string,
        page: number,
        pageWidth: number,
        version: number,
        key?: NodeKey
    ) {
        super(key);
        this.__rawHtml = rawHtml;
        this.__page = page;
        this.__pageWidth = pageWidth;
        this.__version = version;
        this.__metadata = {};
    }

    decorate(): JSX.Element {
        return (
            <>
                {this.__version === 1 && (
                    <RawHtmlComponentV1
                        innerHtml={this.__rawHtml}
                        originalPageWidth={this.__pageWidth}
                        documentHighlights={this.__metadata["documentHighlights"] || []}
                    />
                )}
                {this.__version === 2 && (
                    <RawHtmlComponentV2
                        innerHtml={this.__rawHtml}
                        documentHighlights={this.__metadata["documentHighlights"] || []}
                        commentDocumentHighlights={this.__metadata["commentDocumentHighlights"] || []}
                        navDocumentHighlights={this.__metadata["navigateHighlightElementIDs"] || []}
                        pageScaleFactor={this.__metadata["pageScaleFactor"] ?? 1}
                    />
                )}
            </>
        );
    }
}

export function $createRawHtmlNode({
    rawHtml,
    page,
    pageWidth,
    version
}: RawHtmlPayload): RawHtmlNode {
    return $applyNodeReplacement(
        new RawHtmlNode(
            rawHtml,
            page,
            pageWidth,
            version
        ),
    );
}

export function $isRawHtmlNode(node: LexicalNode | null | undefined,): node is RawHtmlNode {
    return node instanceof RawHtmlNode;
}
