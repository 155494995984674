import React, { useState } from "react";
import {
    Box,
    Button,
    TextField,
    Grid,
    List,
    ToggleButton,
    Tooltip,
    IconButton,
    Toolbar,
} from "@mui/material/";
import { Add, CloseFullscreen, Delete, OpenInFull, Save } from "@mui/icons-material";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import KeyTermListItem from "./KeyTermListItem";
import { useWidgetState } from "../../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../../containers/WidgetWrapper/states";
import DeleteWithConfirmationButton from "../../../components/buttons/DeleteWithConfirmationButton";

interface KeyTermsProps {
    isReadOnly: boolean;
};

const KeyTerms = ({
    isReadOnly
}: KeyTermsProps) => {
    const {
        documentKeyTermsService,
        takerDocumentUpload,
        isKeyTermGroupStateDirty
    } = useKeyTermGroupState();
    const { mutateState, getState } = useWidgetState();

    const [keyTermSearchName, setKeyTermSearchName] = useState<string>("");

    const [saveAllTrigger, setSaveAllTrigger] = useState<boolean>(false);

    const showExpandedSummaries = getState<KeyTermsState>().showExpandedSummaries;

    const uniqueNewKeyTerm = () => {
        const baseNewKeyTerm: string = "key term"
        let numNew: number = 1;
        while (documentKeyTermsService.containsKeyTerm(baseNewKeyTerm + " " + numNew)) {
            numNew += 1;
        }
        return baseNewKeyTerm + " " + numNew;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
        >
            <Box
                width="100%"
                paddingBottom={1}
            >
                <Toolbar
                    variant="dense"
                    disableGutters
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <TextField
                        id="key-term-search"
                        size="small"
                        disabled={documentKeyTermsService?.keyTerms?.length === 0}
                        value={keyTermSearchName}
                        placeholder="Search Terms"
                        onChange={(e: any) => {
                            setKeyTermSearchName(e.target.value);
                        }}
                        variant="outlined"
                        sx={{ backgroundColor: "white" }}
                    />
                    <Box paddingRight={1}>
                        <Tooltip title={"Expand Summaries"}>
                            <IconButton
                                aria-label="Expand Summaries"
                                onClick={() =>
                                    mutateState<KeyTermsState>({showExpandedSummaries: !showExpandedSummaries})
                                }
                                sx={{ marginRight: "10px" }}
                            >
                                {showExpandedSummaries ?
                                    <CloseFullscreen fontSize="inherit" /> :
                                    <OpenInFull fontSize="inherit" />
                                }
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Create New Key Term"}>
                            <IconButton
                                data-testid="create-key-term-button"
                                onClick={() =>
                                    documentKeyTermsService.createNewKeyTerm(uniqueNewKeyTerm())
                                }
                                sx={{ marginRight: "10px" }}
                            >
                                <Add fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Save All Key Term Summaries"}>
                            <IconButton
                                data-testid="save-all-key-term-button"  
                                onClick={() => {
                                    setSaveAllTrigger(!saveAllTrigger);
                                }}
                                sx={{ marginRight: "10px" }}
                            >
                                <Save fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <DeleteWithConfirmationButton
                            disabled={isKeyTermGroupStateDirty}
                            onDelete={() => documentKeyTermsService.removeAllKeyTerms()}
                            confirmationText="Are you sure you want to delete all key terms in this group? This will also remove all of your highlights."
                            tooltipTitle="Delete All Key Terms"
                        />
                    </Box>
                </Toolbar>
            </Box>
            <Box
                width="100%"
                overflow="auto"
                flexGrow={1}
            >
                <List disablePadding>
                    {(documentKeyTermsService.keyTerms) && (
                        documentKeyTermsService.keyTerms.map((keyTerm, index) => (
                            keyTerm.termName.toLowerCase().indexOf(keyTermSearchName.toLowerCase()) !== -1 && (
                                <KeyTermListItem
                                    key={`${takerDocumentUpload?.id}_${keyTerm.identifier}`}
                                    data-testid={`key-term-list-item-${index}`}
                                    takerDocumentUploadId={takerDocumentUpload?.id}
                                    saveAll={saveAllTrigger}
                                    keyTerm={keyTerm}
                                    myIndex={index}
                                    totalLength={documentKeyTermsService.keyTerms?.length}
                                    readOnly={isReadOnly}
                                />
                            )
                        ))
                    )}
                </List>
            </Box>
        </Box>
    );
};

export default KeyTerms;