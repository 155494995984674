import React, { useEffect, useState } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../lexical/wrappers/RichTextEditor/theme";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import ToolbarPlugin from "../lexical/wrappers/RichTextEditor/ToolbarPlugin";
import TableCellResizerPlugin from "../lexical/wrappers/RichTextEditor/TableCellResizer";
import TableCellActionMenuPlugin from '../lexical/wrappers/RichTextEditor/TableActionMenuPlugin';
import SyncSummaryEditorPlugin from "./SyncSummaryEditorPlugin";

import '../lexical/wrappers/RichTextEditor/index.css';

interface EditableSummaryV2Props {
    keyTermName: string;
    triggerEdit: boolean;
    defaultValue: string;
    importedContent?: string[];
    expandSummary: boolean;
    onChangeValue: (s: string) => void;
    readOnly: boolean;
}

const EditableSummaryV2 = ({
    keyTermName,
    triggerEdit,
    defaultValue,
    importedContent,
    expandSummary,
    onChangeValue,
    readOnly
}: EditableSummaryV2Props) => {
    const SUPPORTED_NODES = [
        HeadingNode,
        ListNode,
        ListItemNode,
        TableNode,
        TableCellNode,
        TableRowNode
    ];

    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    const editing = !readOnly && triggerEdit;
    const isEmpty = defaultValue === "" || (defaultValue === null || defaultValue === undefined);
    const containerStyle: SxProps<Theme> = {
        width: '100%',
        display: 'inline-block',
        paddingTop: '10px'
    };

    if (!(editing || (expandSummary && !isEmpty))) {
        containerStyle.maskImage = 'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))';
        containerStyle.maxHeight = '75px';
    }

    return (
        <Box 
            data-testid={`edit-summary-${keyTermName}`}
            sx={containerStyle}
        >
            <LexicalComposer
                initialConfig={{
                    editable: editing,
                    namespace: `edit-summary-${keyTermName}`,
                    theme: EditorThemeClasses,
                    onError(error: any) {
                        console.error(error);
                    },
                    nodes: SUPPORTED_NODES
                }}
            >
                <>
                    {editing && (
                        <ToolbarPlugin hideAlign />
                    )}
                </>
                <SyncSummaryEditorPlugin
                    defaultValue={defaultValue}
                    importedContent={importedContent}
                    triggerEdit={editing}
                />
                <RichTextPlugin
                    contentEditable={
                        <div className="editor-scroller">
                            <div className="editor" ref={onRef}>
                                <ContentEditable 
                                    className="editor-input" 
                                    style={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        minHeight: '25px'
                                    }}
                                />
                            </div>
                        </div>
                    }
                    placeholder={<div className="editor-placeholder"></div>}
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <OnChangePlugin
                    onChange={(editorState, editor) => onChangeValue(JSON.stringify(editorState.toJSON()))}
                />
                <ListPlugin />
                <HistoryPlugin />
                <AutoFocusPlugin />
                <TabIndentationPlugin />
                <HorizontalRulePlugin />
                <ClearEditorPlugin />
                <TablePlugin hasCellMerge />
                <TableCellResizerPlugin />
                <>
                    {floatingAnchorElem && (
                        <TableCellActionMenuPlugin
                            anchorElem={floatingAnchorElem}
                            cellMerge={true}
                        />
                    )}
                </>
            </LexicalComposer>
        </Box>
    );
}

export default EditableSummaryV2;