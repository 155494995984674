import { mergeRegister } from '@lexical/utils';
import {
  COMMAND_PRIORITY_LOW,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import React, { useCallback, useEffect, useRef } from 'react';
import { INSERT_INLINE_COMMAND } from '../AddAnnotationPlugin';
import { Button, ButtonGroup } from '@mui/material';
import { Add, AddComment } from '@mui/icons-material';
import { getDOMRangeRect, setFloatingElemPosition } from '../common';
import { DocumentHighlight } from '../../../../types/taker/documentkeyterms.generated';
import { useCommentsDrawer } from '../../../taker/CommentsDrawer';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';

import './index.css';

const WEIGHTS = [
  10 ** 7,
  10 ** 5,
  10 ** 3,
  10 ** 1,
]

const calculateIdWeight = (wordIdParts: string[]) => {
  return wordIdParts.map((p, i) => (parseInt(p) * WEIGHTS[i])).reduce((a, b) => a + b, 0);
}

const betweenStartAndEnd = (wordIdParts: string[], firstIdParts: string[], lastIdParts: string[]) => {
  const thisWordWeight = calculateIdWeight(wordIdParts);
  const firstWordWeight = calculateIdWeight(firstIdParts);
  const lastWordWeight = calculateIdWeight(lastIdParts);

  // somtimes this happens when a single chunk is highlighted 
  if (lastWordWeight <= firstWordWeight) {
    return thisWordWeight >= firstWordWeight;
  }

  if (lastIdParts.length === 3) {
    return thisWordWeight >= firstWordWeight && thisWordWeight < lastWordWeight;
  }
  return thisWordWeight >= firstWordWeight && thisWordWeight <= lastWordWeight;
}

export default function AddTermFloatingToolbar({
  editor,
  anchorElem,
  isV2Render,
  lexicalDocumentIdentifier,
  pageIndex
}: {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  isV2Render: boolean;
  lexicalDocumentIdentifier: string;
  pageIndex: number;
}): JSX.Element {
  const { takerPermissionState } = useTakerState();
  const { documentKeyTermsService, takerDocumentUpload } = useKeyTermGroupState();
  const { stageCommentFromHighlights } = useCommentsDrawer();
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);

  const isReviewer = takerPermissionState.allGrants.includes("REVIEW");
  const isOwner = takerPermissionState.allGrants.includes("OWNER");
  const canPostComment = isReviewer || isOwner;
  const canAddTerm = isOwner;

  const buildDocumentHighlights = () => {
    const nativeSelection = window.getSelection();
    const documentHighlights: DocumentHighlight[] = [];

    if (nativeSelection !== null && nativeSelection.rangeCount) {
      const range = nativeSelection.getRangeAt(0);
      const nodeIterator = document.createNodeIterator(
        range.commonAncestorContainer,
        NodeFilter.SHOW_TEXT,
        {
          acceptNode: (node) => NodeFilter.FILTER_ACCEPT
        }
      );

      const firstWordElem = range.startContainer.parentElement;
      const lastWordElem = range.endContainer.parentElement;
      const firstWordId = firstWordElem?.id;
      const lastWordId = lastWordElem?.id;

      if (!firstWordId || !lastWordId) {
        return [];
      }

      const firstIdParts = firstWordId.split('-');
      const lastIdParts = lastWordId.split('-');
      
      while (nodeIterator.nextNode()) {
        if (documentHighlights.length === 0 && nodeIterator.referenceNode !== range.startContainer) {
          continue;
        }

        const wordElem = nodeIterator.referenceNode.parentElement;
        if (isV2Render) {
          if (wordElem && wordElem.hasAttribute("id")) { 
            const parts = wordElem.id.split('-');
            if (parts.length === 4 && betweenStartAndEnd(parts, firstIdParts, lastIdParts)) {
              documentHighlights.push({
                elementId: wordElem.id,
                elementType: "WORD"
              });
            } else if (parts.length === 3 && betweenStartAndEnd(parts, firstIdParts, lastIdParts)) {
              documentHighlights.push({
                elementId: wordElem.id,
                elementType: "LINE"
              });
            } else if (parts.length === 2 && betweenStartAndEnd(parts, firstIdParts, lastIdParts)) {
              documentHighlights.push({
                elementId: wordElem.id,
                elementType: "TEXTBOX"
              });
            }
          }
        } else {
          if (wordElem && wordElem.getAttribute("data-element-type") === "word") {
            documentHighlights.push({
              elementId: wordElem.id,
              elementType: "WORD"
            });
          }
        }

        if (nodeIterator.referenceNode === range.endContainer) {
          break;
        }
      }
    }
    return documentHighlights;
  };

  const addTermCommand = () => {
    const documentHighlights: DocumentHighlight[] = buildDocumentHighlights();
    editor.dispatchCommand(INSERT_INLINE_COMMAND, {
      documentHighlights
    });
  };

  const addComment = useCallback(() => {
    if (!takerDocumentUpload) {
      return;
    }

    const documentHighlights: DocumentHighlight[] = buildDocumentHighlights();
    stageCommentFromHighlights(
      documentHighlights,
      documentKeyTermsService.getTextContentForDocumentHighlights(
        lexicalDocumentIdentifier,
        pageIndex,
        documentHighlights
      ),
      takerDocumentUpload.id,
      lexicalDocumentIdentifier,
      pageIndex
    );

    // Remove selection
    const domSelection = window.getSelection();
    if (domSelection !== null) {
      domSelection.removeAllRanges();
    }
  }, [documentKeyTermsService]);

  function mouseMoveListener(e: MouseEvent) {
    if (
      popupCharStylesEditorRef?.current &&
      (e.buttons === 1 || e.buttons === 3)
    ) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
        const x = e.clientX;
        const y = e.clientY;
        const elementUnderMouse = document.elementFromPoint(x, y);

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = 'none';
        }
      }
    }
  }

  function mouseUpListener(e: MouseEvent) {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
        popupCharStylesEditorRef.current.style.pointerEvents = 'auto';
      }
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener);
      document.addEventListener('mouseup', mouseUpListener);

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener);
        document.removeEventListener('mouseup', mouseUpListener);
      };
    }
  }, [popupCharStylesEditorRef]);

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
    const nativeSelection = window.getSelection();

    if (popupCharStylesEditorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);
      setFloatingElemPosition(
        rangeRect,
        popupCharStylesEditorElem,
        anchorElem
      );
    }
  }, [editor, anchorElem]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener('resize', update);
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [editor, updateTextFormatFloatingToolbar, anchorElem]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, updateTextFormatFloatingToolbar]);

  return (
    <div ref={popupCharStylesEditorRef} className="floating-text-format-popup">
      <ButtonGroup
        data-testid='document-highlight-button-group'
        disableElevation
        size="small"
        variant="contained"
        color="inherit"
      >
        {canAddTerm && (
          <Button data-testid='add-term-button' onClick={addTermCommand}>
            <Add />
          </Button>
        )}
        {canPostComment && (
          <Button data-testid='add-comment-button' onClick={addComment}>
            <AddComment />
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
}
