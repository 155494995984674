import { LexicalEditor } from 'lexical';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLayoutEffect } from 'react';
import { Autocomplete, Box, Button, FormControl, FormHelperText, TextField } from '@mui/material';
import { useWidgetState } from '../../../../containers/WidgetWrapper/wrapper';
import { KeyTermsState } from '../../../../containers/WidgetWrapper/states';
import { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';

import './index.css';
import { DragIndicator } from '@mui/icons-material';

export function AddAnnotationInputBox({
  editor,
  cancelAddAnnotation,
  submitAddAnnotation,
  originalRangeRect,
  originalSelectionRects,
  editorParentBoundingBox
}: {
  cancelAddAnnotation: () => void;
  editor: LexicalEditor;
  submitAddAnnotation: (
    keyTermNames: string[],
    lexicalDocumentIdentifier: string
  ) => void;
  originalRangeRect?: DOMRect;
  originalSelectionRects?: ClientRect[]; 
  editorParentBoundingBox?: DOMRect 
}) {
  const [keyTerms, setKeyTerms] = useState<string[]>([]);
  const { documentKeyTermsService } = useKeyTermGroupState();
  const { getState } = useWidgetState();
  const [textFieldDisabled, setTextFieldDisabled] = useState(false);

  const boxRef = useRef<HTMLDivElement>(null);
  const arrowIndicator = useRef<SVGSVGElement>(null);
  const selectionState = useMemo(
    () => ({
      container: document.createElement('div'),
      elements: [],
    }),
    []
  );

  const targetFileUploadItemId = getState<KeyTermsState>().targetFileUploadItemId;

  const currentKeyTermNames = useMemo(() =>
    documentKeyTermsService.keyTerms?.map(kt => kt.termName),
    [documentKeyTermsService.keyTerms]
  );

  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    // If the user clicks outside the box, cancel the annotation. We exclude the MuiAutocomplete-option class because it does not render as a child of the boxRef.
    if (boxRef.current && !boxRef.current.contains(event.target as Node) && !(event.target as Element).className && !(event.target as Element).className.includes('MuiAutocomplete-option')) {
      cancelAddAnnotation();
    }
  };

  const clamp = (x: number, upper: number, lower: number) => { return Math.max(lower, Math.min(x, upper))};
  const updatePointerTriLocation = () => {
    if(arrowIndicator.current && boxRef.current && originalRangeRect) {
      const boxRefBox = boxRef.current.getBoundingClientRect();
      let triRotate = '0deg'
      let correctedTop = boxRefBox.top - pointerTriHeight;
      if(boxRefBox.top + boxRefBox.height/2 > originalRangeRect.bottom + originalRangeRect.height/2) {
        correctedTop = boxRefBox.top - pointerTriHeight;
        triRotate = '0deg'
      } else if(boxRefBox.top + boxRefBox.height/2 < originalRangeRect.bottom - originalRangeRect.height/2) {
        correctedTop = boxRefBox.bottom
        triRotate = '180deg'
      }

      let clampedLeft = originalRangeRect.left + originalRangeRect.width/2 - pointerTriWidth/2;
      if(clampedLeft > boxRefBox.left + boxRefBox.width - pointerTriWidth) {
        clampedLeft = boxRefBox.left + boxRefBox.width - pointerTriWidth;
      } else if(clampedLeft < boxRefBox.left) {
        clampedLeft = boxRefBox.left;
      }

      // This is necessary because it was rendering as a couple pixels off the box, seemingly this is because of the drop shadow for the box
      // this could likely be corrected by accounting for the box shadow in the triangle position calculation
      const forcedOffsetFromBox = -4;

      // If the box is too far to the left or right, put the indicator on the side of the box
      if(clampedLeft + pointerTriWidth/2 < originalRangeRect.left 
        && Math.abs(boxRefBox.top + boxRefBox.height/2 - originalRangeRect.top) < Math.abs(boxRefBox.left + boxRefBox.width/2 - originalRangeRect.left)) {
        clampedLeft = boxRefBox.left + boxRefBox.width + forcedOffsetFromBox;
        correctedTop = originalRangeRect.top + originalRangeRect.height/2 - pointerTriWidth/2;
        correctedTop = clamp(correctedTop, boxRefBox.bottom - pointerTriWidth, boxRefBox.top - forcedOffsetFromBox);
        triRotate = '90deg'
      } else if(clampedLeft + pointerTriWidth/2 > originalRangeRect.right) {
        clampedLeft = boxRefBox.left - pointerTriWidth - forcedOffsetFromBox;
        correctedTop = originalRangeRect.top + originalRangeRect.height / 2 - pointerTriWidth / 2;
        correctedTop = clamp(correctedTop, boxRefBox.bottom - pointerTriWidth, boxRefBox.top - forcedOffsetFromBox);
        triRotate = '270deg';
      }
      
      arrowIndicator.current.style.left = `${clampedLeft}px`
      arrowIndicator.current.style.top = `${correctedTop}px`
      arrowIndicator.current.style.rotate = triRotate;
    }
  }

  //TODO: update this to include modified new values based on a drag event
  const updateLocation = useCallback(() => {
    if (originalRangeRect && originalSelectionRects) {
      const boxElem = boxRef.current;
      if (boxElem !== null) {
        const { left, top, bottom, width } = originalRangeRect;
        let correctedLeft = left + (width / 2) - boxElem.offsetWidth/2;
        if (correctedLeft < 10) {
          correctedLeft = 10;
        }
        if (correctedLeft + boxElem.offsetWidth > window.innerWidth - 10) {
          correctedLeft = window.innerWidth - 10 - boxElem.offsetWidth;
        }
        let correctedTop = bottom + 20 + (window.pageYOffset || document.documentElement.scrollTop);

        // Checks if the box is off screen, if so, put it above the selection
        let above = false;
        if(correctedTop + boxElem.offsetHeight > window.innerHeight) {
          correctedTop = top - boxElem.offsetHeight - 20;
          above = true;
        }

        // Sets the initial arrow indicator position
        if(above && arrowIndicator.current) {
          arrowIndicator.current.style.top = `${correctedTop + boxElem.offsetHeight}px`;
          arrowIndicator.current.style.left = `${originalRangeRect.left + originalRangeRect.width / 2 - pointerTriWidth/2}px`;
          arrowIndicator.current.style.rotate = '180deg';
        } else if (!above && arrowIndicator.current) {
          arrowIndicator.current.style.top = `${correctedTop - pointerTriHeight}px`;
          arrowIndicator.current.style.left = `${originalRangeRect.left + originalRangeRect.width / 2 - pointerTriWidth / 2}px`;
          arrowIndicator.current.style.rotate = '0deg';
        }

        boxElem.style.left = `${correctedLeft}px`;
        boxElem.style.top = `${correctedTop}px`;
        const selectionRectsLength = originalSelectionRects.length;
        const { container } = selectionState;
        const elements: Array<HTMLSpanElement> = selectionState.elements;
        const elementsLength = elements.length;

        for (let i = 0; i < selectionRectsLength; i++) {
          const selectionRect = originalSelectionRects[i];
          let elem: HTMLSpanElement = elements[i];
          if (elem === undefined) {
            elem = document.createElement('span');
            elements[i] = elem;
            container.appendChild(elem);
          }
          const color = '255, 212, 0';
          const style = `position:absolute;top:${selectionRect.top +
            (window.pageYOffset || document.documentElement.scrollTop)
            }px;left:${selectionRect.left}px;height:${selectionRect.height
            }px;width:${selectionRect.width
            }px;background-color:rgba(${color}, 0.3);pointer-events:none;z-index:5;`;
          elem.style.cssText = style;
        }
        for (let i = elementsLength - 1; i >= selectionRectsLength; i--) {
          const elem = elements[i];
          container.removeChild(elem);
          elements.pop();
        }
      }
    }
  }, [editor, selectionState, originalRangeRect, originalSelectionRects, boxRef]);

  useLayoutEffect(() => {
    updateLocation();
    const container = selectionState.container;
    const body = document.body;
    if (body !== null) {
      body.appendChild(container);
      return () => {
        body.removeChild(container);
      };
    }
  }, [selectionState.container, updateLocation]);

  useEffect(() => {
    window.addEventListener('resize', updateLocation);
    return () => {
      window.removeEventListener('resize', updateLocation);
    };
  }, [updateLocation]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const clickSave = () => {
    if (!!targetFileUploadItemId) {
      submitAddAnnotation(
        keyTerms,
        targetFileUploadItemId
      );
    }
  };

  const closeDragElement = () => {
    // stops the drag, sets the text field back to normal
    document.removeEventListener('mouseup', closeDragElement);
    document.removeEventListener('mousemove', elementDrag);
    setTextFieldDisabled(false);
  }

  // drag variables
  var dragOffsetX = 0;
  var dragOffsetY = 0;
  let dragStartX = NaN;
  let dragStartY = NaN;

  const dragMouseDown = (event: React.MouseEvent) => {
    // Starts the drag
    event.preventDefault();
    dragStartX = event.clientX;
    dragStartY = event.clientY;
    document.addEventListener('mouseup', closeDragElement);
    document.addEventListener('mousemove', elementDrag);
  }

  const elementDrag = (event: MouseEvent) => {
    event.preventDefault();

    // gets offset and resets drag start
    dragOffsetX = dragStartX - event.clientX;
    dragOffsetY = dragStartY - event.clientY;
    dragStartX = event.clientX;
    dragStartY = event.clientY;

    // Disables the text field when dragging
    setTextFieldDisabled(true);
    if(boxRef.current) {
      //updates the box position then updates the pointer
      let top = Math.max(boxRef.current.offsetTop - dragOffsetY, editorParentBoundingBox ? editorParentBoundingBox.top : 0);
      top = Math.min(top, window.innerHeight - boxRef.current.offsetHeight);
      let left = Math.max(boxRef.current.offsetLeft - dragOffsetX, editorParentBoundingBox ? editorParentBoundingBox.left - boxRef.current.offsetWidth/2: 0);
      left = Math.min(left, window.innerWidth - boxRef.current.offsetWidth);
      boxRef.current.style.top = `${top}px`;
      boxRef.current.style.left = `${left}px`;
      updatePointerTriLocation();
    }
  }

  interface Point { x: number, y: number}

  // Pointer triangle attributes
  const pointerTriHeight = 15;
  const pointerTriWidth = 20;
  const pointerTriColor = "#ffffff"

  // Pointer triangle points based on height and width
  const pointerPoint1: Point = {x: 0, y:pointerTriHeight}
  const pointerPoint2: Point = {x: pointerTriWidth/2, y:0}
  const pointerPoint3: Point = {x: pointerTriWidth, y:pointerTriHeight}

  return (
    <>
      <svg className = "CommentPlugin_CommentInputBox_ArrowIndicator" style={{position:'absolute',height:`${pointerTriHeight}`, width:`${pointerTriWidth}`}} ref={arrowIndicator}>
        <polygon 
          points={ `${pointerPoint1.x},${pointerPoint1.y} 
                    ${pointerPoint2.x},${pointerPoint2.y} 
                    ${pointerPoint3.x},${pointerPoint3.y}`}
          fill={`${pointerTriColor}`} />
      </svg>
      <div className="CommentPlugin_CommentInputBox" ref={boxRef} style={{overflow:"visible"}}>
        <div onMouseDown={dragMouseDown} style={{ width: '95%',  margin: '1% 2.5% 5px 2.5%', textAlign: 'center', cursor: 'pointer'}}>
          <DragIndicator sx={{color: 'rgb(110, 110, 110)',rotate: '90deg', mt: '-5px', mb:'-5px'}}/>
        </div>
        <FormControl sx={{ m: 1, width: "95%" , marginTop: '0'}}>
          <Autocomplete
            size='small'
            multiple
            data-testid="key-terms-autocomplete"
            options={currentKeyTermNames || []}
            getOptionLabel={(option) => option}
            id="key-terms-input"
            value={keyTerms}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                let newKeyTerms = [...keyTerms];
                newKeyTerms.push(newValue);
                setKeyTerms(newKeyTerms);
              } else {
                setKeyTerms(newValue);
              }
            }}
            freeSolo
            filterSelectedOptions
            disabled={textFieldDisabled}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Key Terms"
                placeholder="Key Terms"
                data-testid="key-terms-input"
              />
            )}
          />
          <FormHelperText>
            Click enter to add a new key term or select 1 or more previous terms.
          </FormHelperText>
        </FormControl>
        <Box m={1}>
          <Button
            data-testid="save-comment-button"
            disabled={(keyTerms.length === 0)}
            size='small'
            color="primary"
            onClick={clickSave}
          >
            Save
          </Button>
          <Button
            data-testid="cancel-comment-button"
            size='small'
            onClick={cancelAddAnnotation}
          >
            Cancel
          </Button>
        </Box>
      </div>
    </>
  );
}
