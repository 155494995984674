import React, { useEffect, useMemo, useState } from "react";
import KeyTermGroup from "./KeyTermGroup";
import { Box, Stack, useTheme } from "@mui/material";
import TakerToolbar from "../../components/taker/TakerToolbar/TakerToolbar";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import WidgetWrapper from "../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../containers/WidgetWrapper/states";
import { useNavigate } from "react-router-dom";
import MainToolbar from "./MainToolbar";
import CommentsDrawer from "../../components/taker/CommentsDrawer";
import { useSideNav } from "../../components/navigation/SideNav";

const buildInitialContentFilteringState = (savedCfState: string | null) => {
    const cfState: KeyTermsState = (!!savedCfState && JSON.parse(savedCfState)) || {
        groupManagerOpen: false,
        targetTakerDocumentUploadId: null,
        showAiSummaries: false,
        showGenerationDialog: false,
        panelViewMode: 0,
        activeAnnotationIds: []
    };
    return cfState;
};

const TakerKeyTerms = () => {
    const { isOpen: isSideNavOpen } = useSideNav();
    const { takerPermissionState, taker, takerDocumentId } = useTakerState();
    const navigate = useNavigate();
    const theme = useTheme();

    const [widgetState, setWidgetState] = useState<KeyTermsState>(
        buildInitialContentFilteringState(localStorage.getItem(`KeyTermsState-${takerDocumentId}`))
    );

    const isReadOnly = useMemo(() =>
        takerPermissionState.isRead && !takerPermissionState.isReadWrite,
        [takerPermissionState]
    );

    /**
     * Sync widgetState with localStorage.
    */
    useEffect(() => {
        if (!!widgetState) {
            const widgetStateCopy = Object.assign({}, widgetState);
            delete widgetStateCopy.navigateHighlightElementIDs;
            localStorage.setItem(`KeyTermsState-${takerDocumentId}`, JSON.stringify(widgetStateCopy));
        }
    }, [widgetState]);

    const contentStyle: React.CSSProperties = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    };
    if (isSideNavOpen) {
        contentStyle.width = 'calc(100vw - 200px)';
        contentStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        });
    } else {
        contentStyle.width = '100vw';
        contentStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        });
    }

    return (
        <Box style={contentStyle}>
            <WidgetWrapper
                widgetState={widgetState}
                setPartialWidgetState={(s) => {
                    setWidgetState(Object.assign({}, widgetState, s));
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        paddingLeft: 1.5,
                        paddingRight: 1.5,
                        borderRadius: 1,
                        backgroundColor: "#F0F4F8",
                        borderBottom: "1px solid rgb(228, 228, 228, 0.9)"
                    }}
                >
                    <Stack width="100%">
                        <TakerToolbar
                            data-testid="taker-toolbar"
                            onClickForward={() => {
                                if (taker) {
                                    navigate(`/mainTaker/${taker.id}/analysis`);
                                }
                            }}
                            expectedCommentType="DOCUMENT_HIGHLIGHT"
                            workflowDisplay={"Key Terms"}
                            workflowDescription={`Please upload a document to begin annotating.`}
                        />
                        <MainToolbar data-testid="main-toolbar" readOnly={isReadOnly} />
                    </Stack>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        overflow: 'hidden'
                    }}
                    flexGrow={1}
                >
                    <CommentsDrawer commentType="DOCUMENT_HIGHLIGHT">
                        <KeyTermGroup data-testid="key-term-group" readOnly={isReadOnly} />
                    </CommentsDrawer>
                </Box>
            </WidgetWrapper>
        </Box>
    );
}

export default TakerKeyTerms;
