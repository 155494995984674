import { useSelector } from "react-redux";
import { Box, Button, Grid, IconButton, Menu, MenuItem, Checkbox, ListItemText } from "@mui/material";
import ViewComfyRoundedIcon from '@mui/icons-material/ViewComfy';
import { RootReducerType } from "../../../redux/models/reduxTypes";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { KeyTerm } from "../../../types/taker/documentkeyterms.generated";
import { useState, useEffect, useMemo } from "react";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { useSnackbar } from "notistack";
import { KeyTermDetailModalState } from "../../../containers/WidgetWrapper/states";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { CategoriesWidget } from "./CategoriesWidget";
import { CurrentInputWidget } from "./CurrentInputWidget";
import { ProposedInputWidget } from "./ProposedInputWidget";
import { HighlightDetailsWidget } from "./HighlightDetailsWidget";
import AutoScalingInput from "../../../components/form/AutoScalingInput";

interface KeyTermDetailModalProps {
    readOnly: boolean;
    defaultKeyTerm: KeyTerm;
    open: boolean;
    onClose: () => void;
}

interface DisplayWidgetMapperProps {
    widget: KeyTermDetailModalWidgets;
    defaultKeyTerm: KeyTerm;
    handleClose: () => void;
    editedSummary?: string;
    setEditedSummary?: (s: string) => void;
    importedContent?: string[];
    setImportedContent: (s: string[]) => void;
}

enum KeyTermDetailModalWidgets {
    CurrentInput,
    ProposedInput,
    HighlightDetails,
    Categories
}

const buildInitialKeyTermDetailModalState = (savedState: string | null) => {
    const keyTermDetailModalState = (!!savedState && JSON.parse(savedState)) || {
        showCurrentInput: true,
        showProposedInput: true,
        showHighlightDetails: true,
        showCategories: false
    };
    return keyTermDetailModalState;
}

const DisplayWidgetMapper = ({ widget, defaultKeyTerm, handleClose, editedSummary, setEditedSummary, importedContent, setImportedContent }: DisplayWidgetMapperProps) => {
    if (widget === KeyTermDetailModalWidgets.CurrentInput) {
        return (
            <CurrentInputWidget
                keyTerm={defaultKeyTerm}
                importedContent={importedContent}
                editedSummary={editedSummary}
                setEditedSummary={setEditedSummary}
            />
        )
    } else if (widget === KeyTermDetailModalWidgets.ProposedInput) {
        return (
            <ProposedInputWidget keyTerm={defaultKeyTerm} />
        )
    } else if (widget === KeyTermDetailModalWidgets.HighlightDetails) {
        return (
            <HighlightDetailsWidget keyTerm={defaultKeyTerm} handleClose={handleClose} setImportedContent={setImportedContent} />
        )
    } else if (widget === KeyTermDetailModalWidgets.Categories) {
        return (
            <CategoriesWidget keyTerm={defaultKeyTerm} />
        )

    } else {
        return (
            <div>Widget not found</div>
        )
    }
}

const ViewFormationAll = (
    component1: KeyTermDetailModalWidgets,
    component2: KeyTermDetailModalWidgets,
    component3: KeyTermDetailModalWidgets,
    component4: KeyTermDetailModalWidgets,
    defaultKeyTerm: KeyTerm,
    handleClose: () => void,
    editedSummary: string,
    setEditedSummary: (s: string) => void,
    importedContent: string[] | undefined,
    setImportedContent: (s: string[]) => void,
) => {
    return (
        <>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedContent={importedContent}
                    setImportedContent={setImportedContent}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component1}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedContent={importedContent}
                    setImportedContent={setImportedContent}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component2}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedContent={importedContent}
                    setImportedContent={setImportedContent}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component3}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedContent={importedContent}
                    setImportedContent={setImportedContent}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component4}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
        </>
    )
}

const ViewFormation3 = (
    component1: KeyTermDetailModalWidgets,
    component2: KeyTermDetailModalWidgets,
    component3: KeyTermDetailModalWidgets,
    defaultKeyTerm: KeyTerm,
    handleClose: () => void,
    editedSummary: string,
    setEditedSummary: (s: string) => void,
    importedContent: string[] | undefined,
    setImportedContent: (s: string[]) => void
) => {
    return (
        <>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedContent={importedContent}
                    setImportedContent={setImportedContent}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component1}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={6} height="50%">
                <DisplayWidgetMapper
                    importedContent={importedContent}
                    setImportedContent={setImportedContent}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component2}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={12} height="50%">
                < DisplayWidgetMapper
                    importedContent={importedContent}
                    setImportedContent={setImportedContent}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component3}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
        </>
    )
}

const ViewFormation2 = (
    component1: KeyTermDetailModalWidgets,
    component2: KeyTermDetailModalWidgets,
    defaultKeyTerm: KeyTerm,
    handleClose: () => void,
    editedSummary: string,
    setEditedSummary: (s: string) => void,
    importedContent: string[] | undefined,
    setImportedContent: (s: string[]) => void
) => {
    return (
        <>
            <Grid item xs={6} height="100%">
                <DisplayWidgetMapper
                    importedContent={importedContent}
                    setImportedContent={setImportedContent}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component1}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={6} height="100%">
                <DisplayWidgetMapper
                    importedContent={importedContent}
                    setImportedContent={setImportedContent}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component2}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
        </>
    )
}

const ViewFormation1 = (
    component1: KeyTermDetailModalWidgets,
    defaultKeyTerm: KeyTerm,
    handleClose: () => void,
    editedSummary: string,
    setEditedSummary: (s: string) => void,
    importedContent: string[] | undefined,
    setImportedContent: (s: string[]) => void
) => {
    return (
        <>
            <Grid item xs={12} height="100%">
                <DisplayWidgetMapper
                    importedContent={importedContent}
                    setImportedContent={setImportedContent}
                    editedSummary={editedSummary}
                    setEditedSummary={setEditedSummary}
                    widget={component1}
                    defaultKeyTerm={defaultKeyTerm}
                    handleClose={handleClose}
                />
            </Grid>
        </>
    )
}


const KeyTermDetailModal = ({ readOnly, defaultKeyTerm, open, onClose }: KeyTermDetailModalProps) => {
    const { takerDocumentId } = useTakerState();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [importedText, setImportedText] = useState<string[]>();
    const openMenu = Boolean(anchorEl);
    const { enqueueSnackbar } = useSnackbar();
    const [editedKeyTermName, setEditedKeyTermName] = useState<string>(defaultKeyTerm.termName);
    const [editedSummary, setEditedSummary] = useState(defaultKeyTerm.summary);
    const { documentKeyTermsService } = useKeyTermGroupState();
    const [widgetState, setWidgetState] = useState<KeyTermDetailModalState>(
        buildInitialKeyTermDetailModalState(localStorage.getItem(`KeyTermDetailModalState-${takerDocumentId}`)),
    );

    useEffect(() => {
        if (!!widgetState) {
            localStorage.setItem(`KeyTermDetailModalState-${takerDocumentId}`, JSON.stringify(widgetState));
        }
    }, [widgetState]);

    const {
        user,
    } = useSelector((state: RootReducerType) => state.auth);

    let isDebugger = useMemo(() => {
        return !!user?.roles.includes('DEBUGGER');
    }, [user]);

    const handleClosePanelMenu = () => {
        setAnchorEl(null);
    };

    const handleCancel = () => {
        // reset to defaults
        setImportedText(undefined);
        setEditedKeyTermName(defaultKeyTerm.termName);
        setEditedSummary(defaultKeyTerm.summary);
        onClose();
    }

    useEffect(() => {
        setEditedSummary(defaultKeyTerm.summary);
    }, [defaultKeyTerm.summary]);

    useEffect(() => {
        setEditedKeyTermName(defaultKeyTerm.termName);
    }, [defaultKeyTerm.termName]);

    let widgetsToDisplay = [];
    let viewFormation = (<div>No widgets to display</div>);
    if (widgetState.showCurrentInput) {
        widgetsToDisplay.push(KeyTermDetailModalWidgets.CurrentInput);
    }
    if (widgetState.showProposedInput) {
        widgetsToDisplay.push(KeyTermDetailModalWidgets.ProposedInput);
    }
    if (widgetState.showHighlightDetails) {
        widgetsToDisplay.push(KeyTermDetailModalWidgets.HighlightDetails);
    }
    if (widgetState.showCategories) {
        widgetsToDisplay.push(KeyTermDetailModalWidgets.Categories);
    }
    if (widgetsToDisplay.length === 4) {
        viewFormation = ViewFormationAll(widgetsToDisplay[0], widgetsToDisplay[1], widgetsToDisplay[2], widgetsToDisplay[3], defaultKeyTerm, handleCancel, editedSummary, setEditedSummary, importedText, setImportedText);
    } else if (widgetsToDisplay.length === 3) {
        viewFormation = ViewFormation3(widgetsToDisplay[0], widgetsToDisplay[1], widgetsToDisplay[2], defaultKeyTerm, handleCancel, editedSummary, setEditedSummary, importedText, setImportedText);
    } else if (widgetsToDisplay.length === 2) {
        viewFormation = ViewFormation2(widgetsToDisplay[0], widgetsToDisplay[1], defaultKeyTerm, handleCancel, editedSummary, setEditedSummary, importedText, setImportedText);
    } else if (widgetsToDisplay.length === 1) {
        viewFormation = ViewFormation1(widgetsToDisplay[0], defaultKeyTerm, handleCancel, editedSummary, setEditedSummary, importedText, setImportedText);
    }

    const buttonElements = [];
    if (!readOnly) {
        buttonElements.push(
            <Button
                sx={{ float: "right" }}
                data-testid="update-key-term-button"
                variant="contained"
                onClick={() => {
                    if (!documentKeyTermsService.containsKeyTerm(editedKeyTermName) || editedKeyTermName == defaultKeyTerm.termName) {
                        documentKeyTermsService.updateKeyTermInfoAndSummary(
                            defaultKeyTerm.termName,
                            editedKeyTermName,
                            defaultKeyTerm.categories,
                            editedSummary
                        );
                        onClose();
                    } else {
                        enqueueSnackbar(`Key Term "${editedKeyTermName}" already exists.`, { variant: "error" })
                    }
                }}
            >
                Save
            </Button>
        );
    }
    buttonElements.push(
        <Button
            data-testid="cancel-key-term-button"
            variant="outlined"
            sx={{
                marginLeft: "10px",
                marginRight: "4px",
                float: "right"
            }}
            onClick={handleCancel}
        >
            Cancel
        </Button>
    );

    return (
        <SimpleModalWrapper
            headerText={`Key Term Details`}
            open={open}
            handleClose={handleCancel}
            disableBackdropClick={true}
            maxWidth='xl'
            buttonElements={buttonElements}
        >
            <Grid
                container
                height="80vh"
                padding={1}
            >
                <Grid
                    item
                    container
                    height="7.5vh"
                    xs={12}
                    padding={1}
                    alignItems={"center"}
                    display={"flex"}
                    sx={{
                        border: "1px solid #ddd",
                        borderRadius: 1,
                        backgroundColor: "white"
                    }}
                >
                    <Grid
                        item
                        xs={4}
                        padding={1}
                        justifyContent="start"
                        alignItems={"center"}
                    >
                        <IconButton
                            data-testid="key-term-detail-modal-view-comfy"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                setAnchorEl(event.currentTarget);
                            }}
                        >
                            <ViewComfyRoundedIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleClosePanelMenu}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem key="Current Input" >
                                <Checkbox
                                    checked={widgetState.showCurrentInput}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setWidgetState({
                                            ...widgetState,
                                            showCurrentInput: event.target.checked
                                        });
                                    }}
                                />
                                <ListItemText primary="Current Input" />
                            </MenuItem>
                            <MenuItem key="Proposed Input" >
                                <Checkbox
                                    checked={widgetState.showProposedInput}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setWidgetState({
                                            ...widgetState,
                                            showProposedInput: event.target.checked
                                        });
                                    }}
                                />
                                <ListItemText primary="Proposed Input" />
                            </MenuItem>
                            <MenuItem key="Highlight Details" >
                                <Checkbox
                                    checked={widgetState.showHighlightDetails}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setWidgetState({
                                            ...widgetState,
                                            showHighlightDetails: event.target.checked
                                        });
                                    }}
                                />
                                <ListItemText primary="Highlight Details" />
                            </MenuItem>
                            {isDebugger && <MenuItem key="Categories" >
                                <Checkbox
                                    checked={widgetState.showCategories}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setWidgetState({
                                            ...widgetState,
                                            showCategories: event.target.checked
                                        });
                                    }}
                                />
                                <ListItemText primary="Categories" />
                            </MenuItem>}
                        </Menu>
                    </Grid>
                    <Grid item xs={4} textAlign="center">
                        <AutoScalingInput
                            data-testid="key-term-name"
                            value={editedKeyTermName}
                            onChange={(newValue) => {
                                setEditedKeyTermName(newValue);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    height="72.5vh"
                    paddingTop={1}
                    xs={12}
                    spacing={1}
                >
                    {viewFormation}
                </Grid>
            </Grid>
        </SimpleModalWrapper>
    );
}

export default KeyTermDetailModal;