import { KeyTerm } from "../../../types/taker/documentkeyterms.generated";
import { Box, Card, CardContent, Divider, Stack, TextField, Typography, Select, MenuItem } from "@mui/material";
import { BoltButton } from "../../../components/buttons/boltButton";
import { useEffect, useMemo, useState } from "react";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import { OptionsWithExtraProps, useSnackbar } from "notistack";
import { useUserScopedAppData } from "../../../containers/UserScopedAppData/UserScopedAppData";
import { useGetTakerDocumentUploadAnalysisSummaryQuery } from "../../../redux/services/taker";
import { TakerDocumentUploadAnalysis } from "../../../redux/models/dataModelTypes";
import { skipToken } from "@reduxjs/toolkit/query";

const TOP_CENTER_OPTION = {
    variant: 'info',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
    }
} as OptionsWithExtraProps<'info'>;

interface ProposedInputWidgetProps {
    keyTerm: KeyTerm;
}



export const ProposedInputWidget = ({ keyTerm }: ProposedInputWidgetProps) => {
    const [aiGeneratedValue, setAIGeneratedValue] = useState("Press the bolt on the right to generate a suggested summary!");
    const [generationArray, setGenerationArray] = useState<TakerDocumentUploadAnalysis[]>([]);
    const [disableInput, setDisableInput] = useState(false);
    const { documentKeyTermsService, takerDocumentUpload, aiSummaryRequests } = useKeyTermGroupState();
    const [activeIndex, setActiveIndex] = useState(0);
    const { flagProvider } = useUserScopedAppData();
    const { enqueueSnackbar } = useSnackbar();
    const { createSingleSummary } = useTakerState();
    const flags = flagProvider.populateFlagValues([
        "DEMO_MODE__enable_demo_mode",
        "AI_FEATURES_KEY_TERMS__enable_ai_for_key_terms"
    ]);

    const singleKeyTermsTakerDocumentUploadAnalyses = useMemo(() => {
        return takerDocumentUpload?.singleKeyTermsTakerDocumentUploadAnalyses;
    }, [takerDocumentUpload]);

    useEffect(() => {
        const pendingReviewAnalyses = takerDocumentUpload?.singleKeyTermsTakerDocumentUploadAnalyses?.filter(
            (analysis) => (analysis.state === 'PENDING_REVIEW' || analysis.state === 'PENDING_GENERATION') && (keyTerm.identifier === analysis.data['key_term_id'])
        ).sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        if (pendingReviewAnalyses && pendingReviewAnalyses.length > 0) {
            setGenerationArray([...pendingReviewAnalyses]);
            if (!!keyTerm?.identifier && aiSummaryRequests && aiSummaryRequests[keyTerm?.identifier] && pendingReviewAnalyses[pendingReviewAnalyses.length - 1].state === 'PENDING_REVIEW' || pendingReviewAnalyses[pendingReviewAnalyses.length - 1].state === 'PENDING_GENERATION_CANCELED') {
                setActiveIndex(pendingReviewAnalyses.length - 1);
                documentKeyTermsService.addOrUpdateLatestAIRequest(
                    {
                        isFulfilled: true,
                        keyTermId: keyTerm.identifier ? keyTerm.identifier : '',
                    }
                )
            } else if (keyTerm?.identifier && aiSummaryRequests && !aiSummaryRequests[keyTerm?.identifier] && pendingReviewAnalyses[pendingReviewAnalyses.length - 1].state === 'PENDING_GENERATION') {
                //if there is a pending generation and no record of it on our side, add a record so we can notify the user by spinning the bolt button
                documentKeyTermsService.addOrUpdateLatestAIRequest(
                    {
                        isFulfilled: false,
                        keyTermId: keyTerm.identifier ? keyTerm.identifier : '',
                    }
                )
            }
        }
    }, [singleKeyTermsTakerDocumentUploadAnalyses, keyTerm.identifier]);

    const {
        data: analysisPayload,
        isSuccess,
        isError,
        isFetching,
        refetch
    } = useGetTakerDocumentUploadAnalysisSummaryQuery((generationArray[activeIndex]?.id && generationArray[activeIndex].state === 'PENDING_REVIEW') ? generationArray[activeIndex].id : skipToken);

    const newSummary = useMemo(() => {
        return analysisPayload || [];
    }, [isFetching, analysisPayload]);


    useMemo(() => {
        if (newSummary?.summary) {
            setAIGeneratedValue(newSummary.summary.trim());
        }
    }, [newSummary]);

    const activeSummaryGeneration = useMemo(() => {
        if (keyTerm.identifier && aiSummaryRequests && aiSummaryRequests[keyTerm.identifier]) {
            return !aiSummaryRequests[keyTerm.identifier].isFulfilled;
        } else {
            return false;
        }
    }, [aiSummaryRequests, keyTerm.identifier]);


    const handleClick = (jobType?: string) => {
        if (flags.DEMO_MODE__enable_demo_mode === "FALSE" && flags.AI_FEATURES_KEY_TERMS__enable_ai_for_key_terms === "TRUE") {
            if (takerDocumentUpload) {
                createSingleSummary(keyTerm.identifier ? keyTerm.identifier : '', takerDocumentUpload?.id, jobType ? jobType : '');
                documentKeyTermsService.addOrUpdateLatestAIRequest({
                    isFulfilled: false,
                    keyTermId: keyTerm.identifier ? keyTerm.identifier : '',
                });
            } else {
                console.error("No takerDocumentUpload found");
            }
        } else {
            const summary = documentKeyTermsService.getMockKeyTermSummary(keyTerm.termName);
            if (!summary) {
                enqueueSnackbar("AI Generated Summary is not available", TOP_CENTER_OPTION);
                return;
            }

            setDisableInput(true);

            const words = summary.split(' ');

            const setInnerReportSectionContent = (wordI: number) => {
                // Replace first child, remove the addition children
                const contatedTokens = words.slice(0, wordI + 1).join(' ');
                setAIGeneratedValue(contatedTokens);
            }

            for (let i = 0; i < words.length; i++) {
                setTimeout(() => {
                    setInnerReportSectionContent(i);
                }, (300 * (i + 1)));
            }
            setTimeout(() => {
                setDisableInput(false);
            }, (300 * words.length));
        }
    };
    let tooltipText = "Generate AI Summary";
    let disableButton = false;
    if (flags.DEMO_MODE__enable_demo_mode === "TRUE" && flags.AI_FEATURES_KEY_TERMS__enable_ai_for_key_terms === "FALSE") {
        tooltipText = "Generate AI Summary";
    } else if (flags.DEMO_MODE__enable_demo_mode === "FALSE" && flags.AI_FEATURES_KEY_TERMS__enable_ai_for_key_terms === "FALSE") {
        tooltipText = "AI Key Term Summary Generation coming soon!";
        disableButton = true;
    }
    return (
        <Card variant="outlined" sx={{ height: "100%", overflowY: "auto" }}>
            <CardContent>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1">
                        Proposed Summary
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {generationArray.length > 0 &&
                            <Select
                                value={activeIndex}
                                onChange={(event) => {
                                    setActiveIndex(event.target.value as number);
                                }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select generation' }}
                                sx={{ marginRight: 3 }}
                            >
                                {generationArray.map((generation, index) => (
                                    <MenuItem key={generation.id} value={index}>
                                        Generation {index + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                        <BoltButton
                            isLoading={activeSummaryGeneration}
                            boltId="generate-summary"
                            isKeyTerm
                            disableAll={disableButton}
                            tooltipText={tooltipText}
                            onClick={(jobType) => {
                                handleClick(jobType)
                            }}

                            onClickDelay={0}
                            sx={{ alignItems: 'center', alignSelf: 'center', marginTop: 'auto', marginBottom: 'auto', width: '5' }}
                            size="small"
                        />
                    </Box>
                </Stack>
                <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
                <Box
                    sx={{
                        width: "100%",
                        display: "inline-flex",
                        alignItems: "center"
                    }}
                >
                    <TextField
                        data-testid="editable-summary-text"
                        id="editable-summary-text"
                        size="medium"
                        value={aiGeneratedValue}
                        variant="outlined"
                        multiline
                        fullWidth
                        disabled={disableInput}
                        InputProps={{
                            readOnly: true,
                            autoComplete: "off"
                        }}
                    />
                </Box>
            </CardContent>
        </Card>
    )
}